import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../../components/header'
import { columnOutgoingPayment } from '../../../../utils/columns'
import { getToday } from '../../../../utils/getDays'
import Layout from '../../../../components/Layout'
import ViewOutgoingPaymentModal from '../../../../components/Modal/outgoingPaymentModal'
import CreateOutgoingPaymentModal from './Create'
import { debouncedSet } from '../../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import {
  fetchOutgoingPayments,
  fetchCashAccounts,
} from '../../../../utils/fetchData'
import PaySupplierModal from './PaySupplier'
import { useSelector } from 'react-redux'

const OutgoingPayments = () => {
  const { t } = useTranslation()
  const formattedDate = getToday()
  const roleNames = useSelector((state) => state.main.roleNames)

  const [currentPage, setCurrentPage] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [addModalVisible, setIsAddModalVisible] = useState(false)
  const [payModalVisible, setIsPayModalVisible] = useState(false)

  const [selectedRowData, setSelectedRowData] = useState(null)
  const [docNum, setDocNum] = useState('')
  const [cardName, setCardName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(formattedDate)

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'outgoingPayments',
      currentPage,
      cardName,
      docNum,
      startDate,
      endDate,
    ],
    queryFn: fetchOutgoingPayments,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: cashAccs = [] } = useQuery({
    queryKey: ['cashAccounts'],
    queryFn: fetchCashAccounts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleAddClick = () => {
    setIsAddModalVisible(true)
  }

  const handlePaySupplierModal = () => {
    setIsPayModalVisible(true)
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }
  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  }

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  }

  const handleDocNumChange = (val) => {
    debouncedSet(val, setDocNum)
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setCardName)
  }

  const columns = columnOutgoingPayment(
    t,
    handleRowClick,
    handleDocNumChange,
    handleCardNameChange,
    cashAccs,
  )

  return (
    <Layout>
      <Header
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
        onBtnClick={handleAddClick}
        isSticky={false}
        {...(roleNames.includes('cashier')
          ? { onPayVendorClick: handlePaySupplierModal }
          : {})}
        {...(roleNames.includes('cashier')
          ? { isPayVendor: true }
          : { isPayVendor: false })}
        {...(roleNames.includes('admin')
          ? { title: 'expenses' }
          : { title: 'outgoingPayment' })}
      />
      <ViewOutgoingPaymentModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          refetch()
          setIsModalVisible(false)
        }}
        refetch={refetch}
      />
      <CreateOutgoingPaymentModal
        visible={addModalVisible}
        onClose={() => {
          setIsAddModalVisible(false)
          refetch()
        }}
      />
      <PaySupplierModal
        visible={payModalVisible}
        onClose={() => {
          setIsPayModalVisible(false)
          refetch()
        }}
      />
    </Layout>
  )
}

export default OutgoingPayments
