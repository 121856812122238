import { Input, Select } from 'antd'
import moment from 'moment'
import ViewIcon from '../assets/icons/viewSvg'
import OrdersIcon from '../assets/icons/OrdersSvg'
import CarIcon from '../assets/icons/CarSvg'
import EditIcon from '../assets/icons/EditSvg'
import DeleteIcon from '../assets/icons/DeleteSvg'

export function columnSalesOrder(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onSlpNameChange,
  onNavigateOrder,
  onNavigateCar,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'docNum',
          key: 'docNum',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date'),
          dataIndex: 'docDate',
          key: 'docDate',
          text: 'center',
          render: (text) => {
            const formattedText = moment(text).format('DD.MM.YYYY')
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('supplier')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('supplier'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'docTotal',
          key: 'docTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record?.docCurrency}`
          },
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('pManager')}
            onChange={(e) => onSlpNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('pManager'),
          dataIndex: 'slpName',
          key: 'slpName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('status'),
          dataIndex: 'u_AbsoluteStatus',
          key: 'u_AbsoluteStatus',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center flex-col gap-2 justify-center">
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
              <button
                onClick={() => {
                  onNavigateOrder(record)
                }}
                className="bg-[#124153] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <OrdersIcon />
              </button>
              <button
                onClick={() => {
                  onNavigateCar(record)
                }}
                className="bg-[#26c92e] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <CarIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}
export function columnPurchaseOrder(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onSlpNameChange,
  isShowManager = true,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'docNum',
          key: 'docNum',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date'),
          dataIndex: 'docDueDate',
          key: 'docDueDate',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('supplier')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('supplier'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'docTotal',
          key: 'docTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record?.docCurrency}`
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('status'),
          dataIndex: 'u_Status',
          key: 'u_Status',
          text: 'center',
          render: (text, record) => <span>{t(`${text}`)}</span>,
        },
      ],
    },
  ]

  if (isShowManager) {
    columns.push({
      title: (
        <div>
          <Input
            placeholder={t('pManager')}
            onChange={(e) => onSlpNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('pManager'),
          dataIndex: 'slpName',
          key: 'slpName',
          text: 'center',
        },
      ],
    })
  }

  columns.push({
    title: '',
    children: [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 120,
        text: 'center',
        render: (text, record) => (
          <div className="flex items-center justify-center">
            <button
              onClick={() => onView(record)}
              className="bg-[#2198c7] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
            >
              <ViewIcon />
            </button>
          </div>
        ),
      },
    ],
  })

  return columns
}

export function columnMySales(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onNavigateOrder,
  onNavigateCar,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'docNum',
          key: 'docNum',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date'),
          dataIndex: 'docDueDate',
          key: 'docDueDate',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('supplier')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('supplier'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'docTotal',
          key: 'docTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record?.docCurrency}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('salesManager'),
          dataIndex: 'slpName',
          key: 'slpName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('status'),
          dataIndex: 'u_AbsoluteStatus',
          key: 'u_AbsoluteStatus',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center flex-col gap-2 justify-center">
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
              <button
                onClick={() => {
                  onNavigateOrder(record)
                }}
                className="bg-[#124153] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <OrdersIcon />
              </button>
              <button
                onClick={() => {
                  onNavigateCar(record)
                }}
                className="bg-[#26c92e] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <CarIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnItems(t, onView, onItemCodeChange, onItemNameChange) {
  const columns = [
    // {
    // 	title: (
    // 		<div>
    // 			<Input
    // 				placeholder={t("itemDocNum")}
    // 				onChange={(e) => onItemCodeChange(e.target.value)}
    // 				style={{ width: "100%" }}
    // 			/>
    // 		</div>
    // 	),
    // 	children: [
    {
      title: t('itemDocNum'),
      dataIndex: 'itemCode',
      key: 'itemCode',
      text: 'center',
    },
    // ],
    // },
    // {
    // 	title: (
    // 		<div>
    // 			<Input
    // 				placeholder={t("itemName")}
    // 				onChange={(e) => onItemNameChange(e.target.value)}
    // 				style={{ width: "100%" }}
    // 			/>
    // 		</div>
    // 	),
    // 	children: [
    {
      title: t('itemName'),
      dataIndex: 'itemName',
      key: 'itemName',
      text: 'center',
      width: 150,
    },
    // ],
    // },
    // {
    // 	title: "",
    // 	children: [
    {
      title: `${t('width')} (${t('mm')})`,
      dataIndex: 'width',
      key: 'width',
      text: 'center',
    },
    // ]},

    // {
    // 	title: "",
    // 	children: [
    {
      title: `${t('height')} (${t('mm')})`,
      dataIndex: 'height',
      key: 'height',
      text: 'center',
    },
    //  ]},
    // 	{
    // 		title: "",
    // 		children: [
    {
      title: t('quantityN'),
      dataIndex: 'onHandSum',
      key: 'onHandSum',
      text: 'center',
    },
    // ]},
    // {
    // 	title: "",
    // 	children: [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      text: 'center',
      render: (text, record) => (
        <button
          onClick={() => onView(record)}
          className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
        >
          <ViewIcon />
        </button>
      ),
    },
    // 	],
    // },
  ]

  return columns
}

export function columnSupplier(t, onView, onCardNameChange, onSeeSales) {
  const columns = [
    {
      title: '',
      children: [
        {
          title: t('supplierCardCode'),
          dataIndex: 'cardCode',
          key: 'cardCode',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('name')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('name'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('phoneNum'),
          dataIndex: 'Cellular',
          key: 'Cellular',
          text: 'center',
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('debt2'),
          dataIndex: 'ordersBal',
          key: 'ordersBal',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 250,
          text: 'center',
          render: (text, record) => (
            <div className="flex justify-center gap-5 items-center">
              {' '}
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
              <button
                onClick={() => onSeeSales(record.cardCode)}
                className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
              >
                {t('act-sverka')}
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnClient(
  t,
  onView,
  onNameChange,
  onTypeChange,
  onPhoneNumberChange,
  onAddressChange,
  onSeeSales,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('name')}
            onChange={(e) => onNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('name'),
          dataIndex: 'cardName',
          key: 'cardName',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('Type')}
            onChange={(e) => onTypeChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('Type'),
          dataIndex: 'type',
          key: 'type',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('phoneNum')}
            onChange={(e) => onPhoneNumberChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('phoneNum'),
          dataIndex: 'Cellular',
          key: 'Cellular',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('Address')}
            onChange={(e) => onAddressChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('Address'),
          dataIndex: 'address',
          key: 'address',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 250,
          text: 'center',
          render: (text, record) => (
            <div className="flex justify-center gap-5 items-center">
              {' '}
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
              <button
                onClick={() => onSeeSales(record.cardCode)}
                className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
              >
                {t('act-sverka')}
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnDebtors(t, onCardNAmeSearch, onSeeSales) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('name')}
            onChange={(e) => onCardNAmeSearch(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('name'),
          dataIndex: 'cardName',
          key: 'cardName',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('groupName'),
          dataIndex: 'groupName',
          key: 'groupName',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('balance'),
          dataIndex: 'balance',
          key: 'balance',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('slpName'),
          dataIndex: 'slpName',
          key: 'slpName',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 150,
          text: 'center',
          render: (text, record) => (
            <button
              onClick={() => onSeeSales(record.cardCode)}
              className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
            >
              {t('act-sverka')}
            </button>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnOutgoingPayment(
  t,
  onView,
  onDocNumChange,
  onCashAcctNameChange,
  cashAccountsData,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'docNum',
          key: 'docNum',
          text: 'center',
        },
      ],
    },

    {
      title: (
        <div>
          <Select
            className="flex  w-[300px] items-center justify-center "
            onChange={(val) => {
              onCashAcctNameChange(val)
            }}
            options={[
              {
                label: <span style={{ fontSize: '10px' }}>{t('all')}</span>,
                value: '',
              },
              ...cashAccountsData?.map((cash) => ({
                label: (
                  <span style={{ fontSize: '10px' }}>{cash.acctName}</span>
                ),
                value: cash.acctName,
              })),
            ]}
          />
        </div>
      ),
      children: [
        {
          title: t('kassa'),
          dataIndex: 'cashAcctName',
          key: 'cashAcctName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date2'),
          dataIndex: 'incomingPaymentDocDate',
          key: 'incomingPaymentDocDate',
          text: 'center',
          render: (text, record) => {
            return `${moment(text).format('DD.MM.YYYY')}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'cashSum',
          key: 'cashSum',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.docCurr}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('comments'),
          dataIndex: 'comments',
          key: 'comments',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex justify-center gap-5 items-center">
              {' '}
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnIncomingPayment(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onCashAcctNameChange,
  cashAccountsData,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'incomingPaymentDocNum',
          key: 'incomingPaymentDocNum',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('Client')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('Client'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Select
            className="flex  w-[300px] items-center justify-center "
            onChange={(val) => {
              onCashAcctNameChange(val)
            }}
            options={[
              {
                label: <span style={{ fontSize: '10px' }}>{t('all')}</span>,
                value: '',
              },
              ...cashAccountsData?.map((cash) => ({
                label: (
                  <span style={{ fontSize: '10px' }}>{cash.acctName}</span>
                ),
                value: cash.acctName,
              })),
            ]}
          />
        </div>
      ),
      children: [
        {
          title: t('kassa'),
          dataIndex: 'acctName',
          key: 'acctName',
          width: 300,
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date2'),
          dataIndex: 'incomingPaymentDocDate',
          key: 'incomingPaymentDocDate',
          text: 'center',
          render: (text, record) => {
            return `${moment(text).format('DD.MM.YYYY')}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'cashSum',
          key: 'cashSum',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.docCurr}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('comments'),
          dataIndex: 'comments',
          key: 'comments',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <button
              onClick={() => onView(record)}
              className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
            >
              <ViewIcon />
            </button>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnBalance(
  t,
  getColumnSearchProps,
  view = true,
  onView,
  like = false,
  onLike,
) {
  const columns = [
    {
      title: t('acctCode'),
      dataIndex: 'acctCode',
      key: 'acctCode',
      text: 'center',
      // ...getColumnSearchProps("docNum"),
    },
    {
      title: t('cashAcctName'),
      dataIndex: 'acctName',
      key: 'acctName',
      text: 'center',
      // ...getColumnSearchProps("docNum"),
    },

    {
      title: t('totalPrice'),
      dataIndex: 'currTotal',
      key: 'currTotal',
      text: 'center',
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return `${formattedText}`
      },
      // ...getColumnSearchProps("mijoz"),
    },
    {
      title: t('currency'),
      dataIndex: 'actCurr',
      key: 'actCurr',
      text: 'center',

      // ...getColumnSearchProps("summa"),
    },
  ]

  return columns
}

export function columnSeeSales(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onSlpNameChange,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('numZakaz')}
            onChange={(e) => onDocNumChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'docNum',
          key: 'docNum',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('docType'),
          dataIndex: 'docType',
          key: 'docType',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date'),
          dataIndex: 'docDate',
          key: 'docDate',
          text: 'center',
          render: (text, record) => {
            return `${moment(text).format('DD.MM.YYYY')}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('supplier'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'docTotal',
          key: 'docTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record?.docCurrency}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('canceled'),
          dataIndex: 'canceled',
          key: 'canceled',
          text: 'center',
          render: (text, record) => {
            const formattedText = text === 'N' ? t('no') : t('yes')
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('docStatus'),
          dataIndex: 'docStatus',
          key: 'docStatus',
          text: 'center',
          render: (text, record) => {
            const formattedText = text === 'O' ? t('open') : t('closed')
            return `${formattedText}`
          },
        },
      ],
    },
  ]

  return columns
}

export function columnItemsAdmin(
  t,
  onView,
  onItemCodeChange,
  onItemNameChange,
  onEdit,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('itemCode')}
            onChange={(e) => onItemCodeChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemCode'),
          dataIndex: 'itemCode',
          key: 'itemCode',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('itemName')}
            onChange={(e) => onItemNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemName'),
          dataIndex: 'itemName',
          key: 'itemName',
          text: 'center',
          width: 150,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('width')} (${t('mm')})`,
          dataIndex: 'u_Width',
          key: 'u_Width',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: `${t('height')} (${t('mm')})`,
          dataIndex: 'u_Height',
          key: 'u_Height',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('price')} `,
          dataIndex: 'price',
          key: 'price',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.currency ? `${record.currency}` : ''}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('quantityN')} `,
          dataIndex: 'u_Kvmetr',
          key: 'u_Kvmetr',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.ugpName}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              {/* <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button> */}
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnStockTransfers(t, onView) {
  const columns = [
    {
      title: t('docNum'),
      dataIndex: 'docNum',
      key: 'docNum',
      text: 'center',
    },
    {
      title: t('fromWhs'),
      dataIndex: 'fromWhsName',
      key: 'fromWhsName',
      text: 'center',
    },
    {
      title: t('toWhs'),
      dataIndex: 'toWhsName',
      key: 'toWhsName',
      text: 'center',
    },
    {
      title: t('date'),
      dataIndex: 'docDate',
      key: 'docDate',
      text: 'center',
      render: (text, record) => {
        return `${moment(text).format('DD.MM.YYYY')}`
      },
    },

    {
      title: t('department'),
      dataIndex: 'u_Department',
      key: 'u_Department',
      text: 'center',
    },

    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      text: 'center',
      render: (text, record) => (
        <div className="flex items-center justify-center flex-col gap-2">
          <button
            onClick={() => onView(record)}
            className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
          >
            <ViewIcon />
          </button>
        </div>
      ),
    },
  ]

  return columns
}

export function columnEmployees(
  t,
  onView,
  onNameChange,
  onRoleChange,
  onDepartmentChange,
  departmentData,
  rolesData,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('name')}
            onChange={(e) => onNameChange(e.target.value)}
            className="w-[200px]"
          />
        </div>
      ),
      children: [
        {
          title: t('name'),
          dataIndex: 'name',
          key: 'name',
          text: 'center',
          render: (text, record) => {
            return `${record.firstName ? `${record.firstName}` : ''} ${record.lastName ? record.lastName : ''}`
          },
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('phoneNum')}
            onChange={(e) => onNameChange(e.target.value)}
            className="w-[150px]"
          />
        </div>
      ),
      children: [
        {
          title: `${t('phoneNum')}`,
          dataIndex: 'mobilePhone',
          key: 'mobilePhone',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('loginName')} `,
          dataIndex: 'employeeCode',
          key: 'employeeCode',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('Password')} `,
          dataIndex: 'externalEmployeeNumber',
          key: 'externalEmployeeNumber',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('slpName')} `,
          dataIndex: 'salesPersonName',
          key: 'salesPersonName',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Select
            className="flex  w-[200px] items-center justify-center "
            style={{ color: 'black' }}
            onChange={(val) => {
              onRoleChange(val)
            }}
            options={[
              { label: t('all'), value: '' },
              ...rolesData?.map((role) => ({
                label: role.roleName,
                value: role.roleId,
              })),
            ]}
          />
        </div>
      ),
      children: [
        {
          title: `${t('role')} `,
          dataIndex: 'role',
          key: 'role',
          width: 300,
          render: (text, record) => {
            return `${record.roleLines[0]?.roleName ?? ''}${record.roleLines[1]?.roleName ? `, ${record.roleLines[1]?.roleName}` : ''} ${record.roleLines[2]?.roleName ? `, ${record.roleLines[2]?.roleName}` : ''} ${record.roleLines[3]?.roleName ? `...` : ''}`
          },
        },
      ],
    },
    {
      title: (
        <div>
          <Select
            className="flex  w-[150px] items-center justify-center "
            style={{ color: 'black' }}
            onChange={(val) => {
              onDepartmentChange(val)
            }}
            options={[
              { label: t('all'), value: '' },
              ...departmentData?.map((department) => ({
                label: department.name,
                value: Number(department.code),
              })),
            ]}
          />
        </div>
      ),
      children: [
        {
          title: `${t('departmentName')} `,
          dataIndex: 'departmentName',
          key: 'departmentName',
          text: 'center',
          width: 150,
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: `${t('SumCash')} `,
          dataIndex: 'u_KassirKassa_UZS',
          key: 'u_KassirKassa_UZS',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('dollarCash')} `,
          dataIndex: 'u_KassirKassa_USD',
          key: 'u_KassirKassa_USD',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('terminalCash')} `,
          dataIndex: 'u_KassirTerminal',
          key: 'u_KassirTerminal',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('whs')} `,
          dataIndex: 'u_WareHouse',
          key: 'u_WareHouse',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              {/* <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button> */}
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnPackageServices(
  t,
  onView,
  onCodeChange,
  onNameChange,
  onDelete,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('serviceGroupCode')}
            onChange={(e) => onCodeChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('serviceGroupCode'),
          dataIndex: 'code',
          key: 'code',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('serviceGroupName')}
            onChange={(e) => onNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('serviceGroupName'),
          dataIndex: 'name',
          key: 'name',
          text: 'center',
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: `${t('createDate')} `,
          dataIndex: 'createDate',
          key: 'createDate',
          text: 'center',
          render: (text, record) => {
            return moment(text).format('DD.MM.YYYY') || '-'
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('package')} `,
          dataIndex: 'u_Package',
          key: 'u_Package',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              <button
                onClick={() => onDelete(record)}
                className="bg-[#d62a2a] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <DeleteIcon />
              </button>
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnExpectedPayments(t, onView, onCardNameChange) {
  const columns = [
    {
      title: '',
      children: [
        {
          title: t('numZakaz'),
          dataIndex: 'salesOrderDocNum',
          key: 'salesOrderDocNum',
          text: 'center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('date'),
          dataIndex: 'docDate',
          key: 'docDate',
          text: 'center',
          render: (text) => {
            const formattedText = moment(text).format('DD.MM.YYYY')
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('cardName')}
            onChange={(e) => onCardNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('cardName'),
          dataIndex: 'cardName',
          key: 'cardName',
          text: 'center',
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('paid'),
          dataIndex: 'dpDocTotal',
          key: 'dpDocTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('debt'),
          dataIndex: 'openAmount',
          key: 'openAmount',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('totalPrice'),
          dataIndex: 'salesOrderDocTotal',
          key: 'salesOrderDocTotal',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center flex-col gap-2 justify-center">
              <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[35px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnBranches(t, onView) {
  const columns = [
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
      text: 'center',
    },

    {
      title: `${t('name')} `,
      dataIndex: 'description',
      key: 'description',
      text: 'center',
    },

    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnCashs(t) {
  const columns = [
    {
      title: t('code'),
      dataIndex: 'acctCode',
      key: 'acctCode',
      text: 'center',
    },

    {
      title: t('kassa'),
      dataIndex: 'acctName',
      key: 'acctName',
      width: 300,
      text: 'center',
    },

    {
      title: t('totalPrice'),
      dataIndex: 'currTotal',
      key: 'currTotal',
      text: 'center',
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return `${formattedText}`
      },
    },

    {
      title: t('currency'),
      dataIndex: 'actCurr',
      key: 'actCurr',
      text: 'center',
    },
  ]

  return columns
}

export function columnLandedCosts(
  t,
  onView,
  onDocNumChange,
  onCardNameChange,
  onCashAcctNameChange,
  cashAccountsData,
) {
  const columns = [
    {
      title: t('numZakaz'),
      dataIndex: 'docNum',
      key: 'docNum',
      text: 'center',
    },

    {
      title: t('supplier'),
      dataIndex: 'suppName',
      key: 'suppName',
      text: 'center',
    },

    {
      title: t('date'),
      dataIndex: 'docDate',
      key: 'docDate',
      text: 'center',
      render: (text) => {
        return `${moment(text).format('DD.MM.YYYY')}`
      },
    },

    {
      title: t('totalPrice'),
      dataIndex: 'docTotal',
      key: 'docTotal',
      text: 'center',
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return `${formattedText} `
      },
    },

    {
      title: t('comments'),
      dataIndex: 'descr',
      key: 'descr',
      text: 'center',
    },

    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      text: 'center',
      render: (text, record) => (
        <button
          onClick={() => onView(record)}
          className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
        >
          <ViewIcon />
        </button>
      ),
    },
  ]

  return columns
}

export function columnItemsTable(
  t,
  onView,
  onItemCodeChange,
  onItemNameChange,
  onItmGrpChange,
  itemGroupsData,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('itemCode')}
            onChange={(e) => onItemCodeChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemCode'),
          dataIndex: 'itemCode',
          key: 'itemCode',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('itemName')}
            onChange={(e) => onItemNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemName'),
          dataIndex: 'itemName',
          key: 'itemName',
          text: 'center',
          width: 150,
        },
      ],
    },
    {
      title: (
        <div>
          <Select
            className="flex  w-[150px] items-center justify-center "
            onChange={(val) => {
              onItmGrpChange(val)
            }}
            options={[
              { value: '', label: t('all') },
              ...itemGroupsData
                ?.filter((item) => {
                  return item.itmsGrpCod !== '101' && item.itmsGrpCod !== '102'
                })
                .map((cash) => ({
                  label: cash.itmsGrpNam,
                  value: cash.itmsGrpNam,
                })),
            ]}
          />
        </div>
      ),
      children: [
        {
          title: t('itemsGroup'),
          dataIndex: 'itmsGrpNam',
          key: 'itmsGrpNam',
          text: 'center',
          width: 150,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('width')} (${t('mm')})`,
          dataIndex: 'u_Width',
          key: 'u_Width',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: `${t('height')} (${t('mm')})`,
          dataIndex: 'u_Height',
          key: 'u_Height',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('price')} `,
          dataIndex: 'price',
          key: 'price',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.currency ? `${record.currency}` : ''}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('quantityN')} `,
          dataIndex: 'u_Kvmetr',
          key: 'u_Kvmetr',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.ugpName}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              {/* <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button> */}
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}

export function columnServicesTable(
  t,
  onView,
  onItemCodeChange,
  onItemNameChange,
  onItmGrpChange,
  itemGroupsData,
) {
  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('itemCode')}
            onChange={(e) => onItemCodeChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemCode'),
          dataIndex: 'itemCode',
          key: 'itemCode',
          text: 'center',
        },
      ],
    },
    {
      title: (
        <div>
          <Input
            placeholder={t('itemName')}
            onChange={(e) => onItemNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('itemName'),
          dataIndex: 'itemName',
          key: 'itemName',
          text: 'center',
          width: 150,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('itemsGroup'),
          dataIndex: 'itmsGrpNam',
          key: 'itmsGrpNam',
          text: 'center',
          width: 150,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('width')} (${t('mm')})`,
          dataIndex: 'u_Width',
          key: 'u_Width',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} `
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: `${t('height')} (${t('mm')})`,
          dataIndex: 'u_Height',
          key: 'u_Height',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('price')} `,
          dataIndex: 'price',
          key: 'price',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.currency ? `${record.currency}` : ''}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: `${t('quantityN')} `,
          dataIndex: 'u_Kvmetr',
          key: 'u_Kvmetr',
          text: 'center',
          render: (text, record) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return `${formattedText} ${record.ugpName}`
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          text: 'center',
          render: (text, record) => (
            <div className="flex items-center justify-center flex-col gap-2">
              {/* <button
                onClick={() => onView(record)}
                className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
              >
                <ViewIcon />
              </button> */}
              <button
                onClick={() => onView(record)}
                className="bg-[#1d5870] text-white w-[30px] h-[30px] rounded-lg flex justify-center items-center"
              >
                <EditIcon />
              </button>
            </div>
          ),
        },
      ],
    },
  ]

  return columns
}
