import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnPurchaseOrder } from '../../../utils/columns'
import { getToday } from '../../../utils/getDays'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import ViewPurchasesModal from '../../../components/Modal/viewPurchasesModal'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { fetchPurchases } from '../../../utils/fetchData'
import { useSelector } from 'react-redux'

const PurchaseOrder = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const roleNames = useSelector((state) => state.main.roleNames)
  const formattedDate = getToday()

  const [currentPage, setCurrentPage] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [docNum, setDocNum] = useState('')
  const [cardName, setCardName] = useState('')
  const [slpName, setSlpName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(formattedDate)
  console.log('roleNames', roleNames)

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'purchaseOrder',
      currentPage,
      cardName,
      docNum,
      startDate,
      endDate,
      slpName,
      'api/purchase/xarid-uchun-buyurtma',
    ],
    queryFn: fetchPurchases,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }
  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  }

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  }

  const handleDocNumChange = (val) => {
    debouncedSet(val, setDocNum)
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setCardName)
  }

  const handleSlpNameChange = (val) => {
    debouncedSet(val, setSlpName)
  }

  const columns = columnPurchaseOrder(
    t,
    handleRowClick,
    handleDocNumChange,
    handleCardNameChange,
    handleSlpNameChange,
    roleNames.includes('Supplier') ? false : true,
  )
  return (
    <Layout>
      <Header
        title={'purchase-order'}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        {...(roleNames.includes('Supplier')
          ? {
              onBtnClick: () => {
                navigate('/create-purchase-order')
              },
            }
          : {})}
        isSticky={false}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
      />
      <ViewPurchasesModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          refetch()
          setIsModalVisible(false)
        }}
        refetch={refetch}
        {...(roleNames.includes('Wrhmanager') ? { ship: true } : {})}
        {...(roleNames.includes('Supplier') ? { purchaseOrder: true } : {})}
      />
    </Layout>
  )
}

export default PurchaseOrder
