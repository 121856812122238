import { Modal, Input, Table, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import InvoicePaymentModal from './invoicePaymentModal'
const ViewShippedSalesOrderModal = ({
  visible,
  data,
  onClose,
  onClick,
  finish = false,
  incoming = false,
  refetch,
}) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dueDate, setDueDate] = useState('')

  useEffect(() => {
    if (data?.docDueDate) {
      setDueDate(moment(data.docDueDate).format('DD.MM.YYYY'))
    }
  }, [data])

  if (!data) return null

  const columns = [
    {
      title: t('item'),
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      width: 150,
      render: (text, record, index) => {
        return <span>{text}</span>
        // className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}
      },
    },

    {
      title: t('quantityN'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span> {formattedText}</span>
      },
    },
    {
      title: t('kv.m'),
      dataIndex: 'ugpCode',
      key: 'ugpCode',
    },

    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },

    {
      title: t('totalMiqdor'),
      dataIndex: 'lineTotal',
      key: 'lineTotal',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },
  ]

  const tableData = data?.documentLines.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    whsName: item.whsName,
    price: item.price,
    lineTotal: item.lineTotal,
    ugpCode: item.ugpCode,
  }))

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('order')} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="90%"
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-bold">{t('mijoz')}</p>
              <Input
                type="text"
                value={data.cardName}
                className="flex  w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('status')}</p>
              <Input
                // className="flex  w-full items-center justify-center sm:w-[160px]"
                value={data?.u_AbsoluteStatus}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('currency')}</p>
              <Input
                value={data?.docCurrency}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('date')}</p>
              <Input
                type="text"
                value={dueDate}
                style={{ color: 'black' }}
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: '100%' }}
            bordered
          />

          <div className="w-full flex items-center justify-between mt-20  ">
            <div className="flex items-center gap-3 ">
              <p className="font-bold">{t('totalForDocument')}:</p>
              <Input
                type="text"
                value={`${new Intl.NumberFormat('fr-FR').format(data.docTotal)} USD`}
                className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>
          </div>
        </div>
        <InvoicePaymentModal
          visible={isModalVisible}
          data={data}
          onClose={() => setIsModalVisible(false)}
        />
      </div>
    </Modal>
  )
}

export default ViewShippedSalesOrderModal
