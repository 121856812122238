import React, { useState } from 'react'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnSalesOrder } from '../../../utils/columns'
import { getToday } from '../../../utils/getDays'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ViewSalesOrderModal from '../../../components/Modal/viewSalesOrderModal'
import { useQuery } from '@tanstack/react-query'
import { fetchSales } from '../../../utils/fetchData'
import { setOrdersPage } from '../../../slice/mainSlice'
import { useDispatch } from 'react-redux'
import { debouncedSet } from '../../../utils/debounce'

const SalesOrder = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const role = useSelector((state) => state.main.role)
  const { department, roleNames } = useSelector((state) => state.main)
  const formattedDate = getToday()

  const [currentPage, setCurrentPage] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [docNum, setDocNum] = useState('')
  const [cardName, setCardName] = useState('')
  const [slpName, setSlpName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(formattedDate)

  const { data = [], isLoading } = useQuery({
    queryKey: [
      'salesOrder',
      currentPage,
      cardName,
      docNum,
      startDate,
      endDate,
      slpName,
      roleNames.includes('Supplier') || roleNames.includes('admin')
        ? ''
        : department,
      'api/sales/sotuv-buyurtmasi',
    ],
    queryFn: fetchSales,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }

  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  }

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  }

  const handleDocNumChange = (val) => {
    debouncedSet(val, setDocNum)
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setCardName)
  }
  const handleSlpNameChange = (val) => {
    debouncedSet(val, setSlpName)
  }

  const handleNavigate = (record) => {
    dispatch(setOrdersPage(record))
    sessionStorage.setItem('orderData', JSON.stringify(record))

    const url = `/orders`
    window.open(url, '_blank')
  }

  const handleNavigateCar = (record) => {
    dispatch(setOrdersPage(record))
    sessionStorage.setItem('orderData', JSON.stringify(record))

    const url = `/marshrut`
    window.open(url, '_blank')
  }

  const columns = columnSalesOrder(
    t,
    handleRowClick,
    handleDocNumChange,
    handleCardNameChange,
    handleSlpNameChange,
    handleNavigate,
    handleNavigateCar,
  )

  const handleAdd = () => {
    navigate('add')
  }

  return (
    <Layout>
      <Header
        title={roleNames.includes('admin') ? t('orders') : t('SalesOrder')}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        isSticky={false}
        formattedDate={formattedDate}
        {...(roleNames.includes('Sales Manager') || roleNames.includes('admin')
          ? { onBtnClick: handleAdd }
          : {})}
      />
      <ViewSalesOrderModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => setIsModalVisible(false)}
      />
    </Layout>
  )
}

export default SalesOrder
