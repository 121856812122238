import React, { useState } from 'react'
import { Table, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import Layout from '../../../components/Layout/index'
import { useQuery } from '@tanstack/react-query'
import {
  fetchWarehouse,
  fetchReports,
  fetchReportsWithWhs,
} from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const Reports = () => {
  const { getMe } = useSelector((state) => state.main)
  const { t } = useTranslation()
  const role = get(getMe, 'jobTitle', '')

  const [currentPage, setCurrentPage] = useState(0)
  const [itemName, setItemName] = useState('')
  const [warehouse, setWarehouse] = useState('')
  const [whsName, setWhsName] = useState('')

  const { data: warehouseData = [] } = useQuery({
    queryKey: ['warehouses'],
    queryFn: fetchWarehouse,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: reportsData = [], isLoading } = useQuery({
    queryKey: ['reports', currentPage, itemName],

    queryFn: fetchReports,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: reportsDataWithWhs = [], isLoading: reportsWithWhsLoading } =
    useQuery({
      queryKey: ['reportsWithWhs', currentPage, itemName, warehouse],

      queryFn: fetchReportsWithWhs,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    })
  const handleItemNameChange = (val) => {
    debouncedSet(val, setItemName)
  }

  const columns = [
    {
      title: (
        <div>
          <Input
            placeholder={t('item')}
            onChange={(e) => handleItemNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('item'),
          dataIndex: 'itemName',
          key: 'itemName',
          width: 200,
          fixed: 'left',
          render: (text) => <b>{text}</b>,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('available'),
          dataIndex: 'available',
          key: 'available',

          render: (text, record) => {
            const warehouseInfo = record.inventoryItemWhs[0]
            const onHand = warehouseInfo ? warehouseInfo.available : '0'
            const formattedonHand = new Intl.NumberFormat('fr-FR').format(
              onHand,
            )
            return <span>{formattedonHand}</span>
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('quantityReport'),
          dataIndex: 'onHandSum',
          key: 'onHandSum',

          render: (text) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return <span>{formattedText}</span>
          },
        },
      ],
    },

    ...warehouseData.map((item) => ({
      title: '',
      children: [
        {
          title: `${item.name} (${t('ordered')})`,
          dataIndex: `${item.code}_onHand`,
          key: `${item.code}_onHand`,
          render: (text, record) => {
            const warehouseInfo = record.inventoryItemWhs.find(
              (w) => w.warehouseName === item.name,
            )
            const onHand = warehouseInfo ? warehouseInfo.onOrder : '0'
            const formattedOnHand = new Intl.NumberFormat('fr-FR').format(
              onHand,
            )
            return <span>{formattedOnHand}</span>
          },
        },
        {
          title: `${item.name} (${t('available')})`,
          dataIndex: `${item.code}_available`,
          key: `${item.code}_available`,
          render: (text, record) => {
            const warehouseInfo = record.inventoryItemWhs.find(
              (w) => w.warehouseName === item.name,
            )
            const available = warehouseInfo ? warehouseInfo.available : '0'
            const formattedAvailable = new Intl.NumberFormat('fr-FR').format(
              available,
            )
            return <span>{formattedAvailable}</span>
          },
        },
      ],
    })),
  ]

  const columnsWithWhs = [
    {
      title: (
        <div>
          <Input
            placeholder={t('item')}
            onChange={(e) => handleItemNameChange(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      ),
      children: [
        {
          title: t('item'),
          dataIndex: 'itemName',
          key: 'itemName',
          width: 200,
          fixed: 'left',
          render: (text) => <b>{text}</b>,
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('available'),
          dataIndex: 'available',
          key: 'available',

          render: (text, record) => {
            const formattedonHand = new Intl.NumberFormat('fr-FR').format(text)
            return <span>{formattedonHand}</span>
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('quantityReport'),
          dataIndex: 'onHand',
          key: 'onHand',

          render: (text) => {
            const formattedText = new Intl.NumberFormat('fr-FR').format(text)
            return <span>{formattedText}</span>
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('onOrder'),
          dataIndex: 'onOrder',
          key: 'onOrder',

          render: (text, record) => {
            const formattedonHand = new Intl.NumberFormat('fr-FR').format(text)
            return <span>{formattedonHand}</span>
          },
        },
      ],
    },

    {
      title: '',
      children: [
        {
          title: t('price'),
          dataIndex: 'lastPurPrc',
          key: 'lastPurPrc',

          render: (text, record) => {
            const formattedonHand = new Intl.NumberFormat('fr-FR').format(text)
            return (
              <span>
                {`${formattedonHand} ${record.lastPurCur ? record.lastPurCur : ''}`}{' '}
              </span>
            )
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('whs'),
          dataIndex: 'whsName',
          key: 'whsName',

          render: (text) => {
            return <span>{whsName}</span>
          },
        },
      ],
    },
  ]

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  return (
    <Layout>
      <div className="m-5 border-[2px] border-gray-200">
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
          {t('reportsWhs')}
        </h1>

        <div className="mt-6 sm:ml-10 sm:mt-10">
          <div className="mb-4 flex flex-col justify-between sm:flex-row">
            {role === 'admin' ? (
              <div className="flex flex-col gap-2">
                <p>{t('whs')}</p>
                <Select
                  value={warehouse}
                  onChange={(value, label) => {
                    setWhsName(label.label)
                    setWarehouse(value)
                  }}
                  className="w-full sm:w-[200px] h-10"
                  options={warehouseData.map((whs) => {
                    return {
                      label: whs.name,
                      value: whs.code,
                    }
                  })}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="mt-2 flex sm:mr-10">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
                className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
              >
                {t('previous')}
              </button>
              <div className="bg-[#099204]  w-[20px] h-[30px] flex items-center  justify-center">
                <span className="text-center text-white">
                  {currentPage + 1}
                </span>
              </div>
              <button
                onClick={handleNextPage}
                disabled={
                  warehouse
                    ? reportsDataWithWhs?.[0]?.items.length !== 30
                    : reportsData.length !== 30
                }
                className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
              >
                {t('next')}
              </button>
            </div>
          </div>
        </div>

        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

        <div className="ml-10">
          <Table
            columns={warehouse ? columnsWithWhs : columns}
            dataSource={
              warehouse ? reportsDataWithWhs?.[0]?.items : reportsData
            }
            pagination={false}
            className="mt-6 w-full sm:mt-10"
            rowKey="zakaz"
            bordered
            scroll={{ x: 2000 }}
            sticky={true}
            loading={isLoading || reportsWithWhsLoading}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Reports
