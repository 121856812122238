import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnPackageServices } from '../../../utils/columns'
import { getToday } from '../../../utils/getDays'
import Layout from '../../../components/Layout'
import CreatePackageModal from '../../../components/Modal/createPackageModal'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../../utils/debounce'
import { http } from '../../../services/http'
import EditPackageModal from '../../../components/Modal/editPackageModal'
import SuccessModal from '../../../components/Modal/SuccessModal/index'
import ErrorModal from '../../../components/Modal/ErrorModal/index'
import ConfirmExitModal from '../../../components/Modal/ConfirmExitModal/index'

const PackageOfServices = () => {
  const { t } = useTranslation()
  const formattedDate = getToday()
  const successRef = useRef()
  const errorRef = useRef()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectedViewRowData, setSelectedViewRowData] = useState(null)
  const [selectedDeleteData, setSelectedDeleteData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [name, setName] = useState('')
  const [code, setCode] = useState('')

  const fetchItems = async ({ queryKey }) => {
    const [_, name, code, page] = queryKey
    try {
      let url = `api/packageofservices?code=${code}&name=${name}&pageSize=10&skip=${page}`
      const { data } = await http.get(url)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['packageServices', name, code, currentPage],
    queryFn: fetchItems,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }
  const handleViewRowClick = (record) => {
    setSelectedViewRowData(record)
    setIsViewModalVisible(true)
  }

  const handleCodeChange = (val) => {
    debouncedSet(val, setCode)
  }

  const handleNameChange = (val) => {
    debouncedSet(val, setName)
  }

  const deleteData = async (record) => {
    setLoading(true)

    try {
      await http.delete(`api/packageofservices/${record.code}`)
      successRef.current.open(t('sucDeleted'))
      refetch()
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleModalYes = () => {
    setIsConfirmModalVisible(false)
    deleteData(selectedDeleteData)
  }
  const handleModalNo = () => {
    setIsConfirmModalVisible(false)
  }
  const handleModalCancel = () => {
    setIsConfirmModalVisible(false)
  }

  const onDeleteClick = (record) => {
    setIsConfirmModalVisible(true)
    setSelectedDeleteData(record)
  }

  const columns = columnPackageServices(
    t,
    handleViewRowClick,
    handleCodeChange,
    handleNameChange,
    onDeleteClick,
  )

  return (
    <Layout>
      <Header
        title={'packageServices'}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onBtnClick={handleRowClick}
        formattedDate={formattedDate}
        isSticky={false}
      />
      <CreatePackageModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          setIsModalVisible(false)
          setCurrentPage(0)
          refetch()
        }}
      />
      <EditPackageModal
        visible={isViewModalVisible}
        data={selectedViewRowData}
        onClose={() => {
          setIsViewModalVisible(false)
          refetch()
        }}
      />
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
      <ConfirmExitModal
        visible={isConfirmModalVisible}
        handleClickCancel={handleModalCancel}
        handleClickNo={handleModalNo}
        handleClickYes={handleModalYes}
      />
    </Layout>
  )
}

export default PackageOfServices
