import React, { useEffect, useRef } from 'react'
import JsBarcode from 'jsbarcode'

const BarcodeGenerator = ({ docEntry }) => {
  const barcodeRef = useRef(null)

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, docEntry, {
        format: 'CODE128',
        lineColor: '#000', // Line color
        width: 2, // Bar width
        height: 30, // Bar height
        displayValue: true, // Display the value below the barcode
        fontSize: 14, // Font size for the value
        margin: 10, // Margin around the barcode
      })
    }
  }, [docEntry])

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <svg ref={barcodeRef}></svg>
    </div>
  )
}

export default BarcodeGenerator
