import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnStockTransfers } from '../../../utils/columns'
import { getToday } from '../../../utils/getDays'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import ViewStockTransfersModal from '../../../components/Modal/viewStockTransfersModal'
import CreateStockTransferlinesModal from '../../../components/Modal/createStockTransferlinesModal'
import { useQuery } from '@tanstack/react-query'
import { fetchStockTransfers } from '../../../utils/fetchData'
import { useSelector } from 'react-redux'

const StockTransfers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const role = useSelector((state) => state.main.role)
  const formattedDate = getToday()

  const [currentPage, setCurrentPage] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(formattedDate)

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['stockTransfers', currentPage],
    queryFn: fetchStockTransfers,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  })

  const handleNextPage = () => {
    if (data.length === 20) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }
  const handleAddBtnClick = () => {
    setIsCreateModalVisible(true)
  }
  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  }

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  }

  const columns = columnStockTransfers(t, handleRowClick)
  return (
    <Layout>
      <Header
        title={'stockTransfers'}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 20}
        columns={columns}
        fdata={data}
        loading={isLoading}
        {...(role === 'admin'
          ? {
              onBtnClick: () => handleAddBtnClick(),
            }
          : {})}
        isSticky={false}
      />
      <ViewStockTransfersModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          refetch()
          setIsModalVisible(false)
        }}
        refetch={refetch}
      />
      <CreateStockTransferlinesModal
        visible={isCreateModalVisible}
        onClose={() => {
          refetch()
          setIsCreateModalVisible(false)
        }}
        refetch={refetch}
      />
    </Layout>
  )
}

export default StockTransfers
