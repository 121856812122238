import { Modal, Input, Button, Select } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'

const CreteCustomerModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('+998 (')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [passportSeries, setPassportSeries] = useState('')
  const [passportExpDate, setPassportExpDate] = useState('')
  const [passportGivenBy, setPassportGivenBy] = useState('')
  const [customerType, setCustomerType] = useState('')
  const [customerTypesData, setCustomerTypesData] = useState([])

  const postData = async () => {
    setLoading(true)
    const cleanedPhone = phoneNumber.replace(/[\(\)\-\_\" "]/g, '')
    try {
      const { data } = await http.post('api/businesspartners/mijozlar', {
        cardName: name,
        phoneNumber: cleanedPhone,
        address,
        email,
        passportSeries,
        passportExpDate,
        passportGivenBy,
        type: customerType,
      })
      console.log(data)
      if (onConfirm) {
        onConfirm({
          name: data.cardName,
          phoneNumber: data.Cellular,
          cardCode: data.cardCode,
        })
      }
      onClose()

      successRef?.current?.open(t('sucCreated'))
    } catch (error) {
      onClose()

      errorRef?.current?.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    setName('')
    setPhoneNumber('+998 (')
    setAddress('')
    setEmail('')
    setPassportSeries('')
    setPassportExpDate('')
    setPassportGivenBy('')
    setCustomerType('')
  }

  useEffect(() => {
    fetchBpType()
  }, [])

  const fetchBpType = async () => {
    try {
      const { data } = await http.get('api/businesspartners/bp-types')
      setCustomerTypesData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (e) => {
    let input = e.target.value.replace(/[^\d]/g, '')

    if (!input.startsWith('998')) {
      input = '998' + input
    }
    if (input.length <= 3) {
      input = `+${input}`
    } else if (input.length <= 5) {
      input = `+${input.slice(0, 3)} (${input.slice(3)}`
    } else if (input.length <= 8) {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5)}`
    } else if (input.length <= 10) {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5, 8)}-${input.slice(8)}`
    } else {
      input = `+${input.slice(0, 3)} (${input.slice(3, 5)}) ${input.slice(5, 8)}-${input.slice(8, 10)}-${input.slice(10, 12)}`
    }

    setPhoneNumber(input)
  }

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('addClient')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="60%"
    >
      <div className="w-full px-2 sm:px-14">
        <div className="flex mt-4 items-center justify-between sm:mt-5">
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('clientName')} (*)</p>
            <Input
              type="text"
              onChange={(e) => {
                setName(e.target.value)
              }}
              value={name}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>

          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('customerType')} (*)</p>
            <Select
              type="text"
              onChange={(val) => {
                setCustomerType(val)
              }}
              value={customerType}
              className="flex h-10 w-[250px] items-center justify-center"
            >
              {customerTypesData.map((type, idx) => (
                <Select.Option key={idx} value={type.fldValue}>
                  {`${type.descr}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('Address')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              value={address}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('email')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>

        <div className="flex items-center justify-between mt-5">
          <div className="flex flex-col gap-3">
            <p className="font-bold">{t('phoneNum')} (*)</p>

            <Input
              type="text"
              value={phoneNumber}
              onChange={handleChange}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('passportSeries')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setPassportSeries(e.target.value)
              }}
              value={passportSeries}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('passportExpDate')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setPassportExpDate(e.target.value)
              }}
              value={passportExpDate}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
          <div className=" flex flex-col gap-3 ">
            <p className="font-bold">{t('passportGivenBy')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setPassportGivenBy(e.target.value)
              }}
              value={passportGivenBy}
              className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>
        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
              resetForm()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={postData}
            loading={loading}
            disabled={loading || !name || !phoneNumber || !customerType}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
        onConfirm={() => {
          resetForm()
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default CreteCustomerModal
