import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnItemsAdmin } from '../../../utils/columns'
import { getToday } from '../../../utils/getDays'
import Layout from '../../../components/Layout'
import CreteItemAdminModal from '../../../components/Modal/createItemAdminModal'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../../utils/debounce'
import { http } from '../../../services/http'
import EditItemsModal from '../../../components/Modal/editItemsModal'

const ItemsAdmin = () => {
  const { t } = useTranslation()
  const formattedDate = getToday()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectedViewRowData, setSelectedViewRowData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemName, setItemName] = useState('')
  const [itemCode, setItemCode] = useState('')
  const [category, setCategory] = useState('')
  const [itemType, setItemType] = useState('')

  const fetchItems = async ({ queryKey }) => {
    const [_, itemName, itemCode, category, itemType, page] = queryKey
    try {
      let url = `api/items/sevices?itemName=${itemName}&itemType=${itemType}&itemCode=${itemCode}&category=${category}&pageSize=10&skip=${page}`
      const { data } = await http.get(url)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['items', itemName, itemCode, category, itemType, currentPage],
    queryFn: fetchItems,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }
  const handleViewRowClick = (record) => {
    setSelectedViewRowData(record)
    setIsViewModalVisible(true)
  }

  const handleItemCodeChange = (val) => {
    debouncedSet(val, setItemCode)
  }

  const handleItemNameChange = (val) => {
    debouncedSet(val, setItemName)
  }

  const columns = columnItemsAdmin(
    t,
    handleViewRowClick,
    handleItemCodeChange,
    handleItemNameChange,
  )

  return (
    <Layout>
      <Header
        title={'items'}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        onBtnClick={handleRowClick}
        formattedDate={formattedDate}
        isSticky={false}
      />
      <CreteItemAdminModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => {
          setIsModalVisible(false)
          setCurrentPage(0)
          refetch()
        }}
      />
      <EditItemsModal
        visible={isViewModalVisible}
        data={selectedViewRowData}
        onClose={() => {
          setIsViewModalVisible(false)
          refetch()
        }}
      />
    </Layout>
  )
}

export default ItemsAdmin
