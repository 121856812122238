import { Modal, Input, Table, Button, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import OrderPaymentModal from './orderPaymentModal'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { useQuery } from '@tanstack/react-query'
const ViewExpectedPaymentsModal = ({
  visible,
  data,
  onClose,
  onClick,
  finish = false,
  incoming = false,
  refetch,
}) => {
  const { t } = useTranslation()

  const successRef = useRef()
  const errorRef = useRef()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [path, setPath] = useState([])
  const [docEntry, setDocEntry] = useState('')

  const fetchOneSale = async ({ queryKey }) => {
    const [_, docEntry] = queryKey
    try {
      let url = `api/sales/sotuv-buyurtmasi?docEntry=${docEntry}`
      const { data } = await http.get(url)
      return data.data[0]
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data?.docEntry) {
      setDocEntry(data?.docEntry)
    }
  }, [data])

  const viewImage = async (index) => {
    setBtnLoading(true)
    const image = path[index]

    try {
      const res = await http.get(`api/assets/download/${image.path}`, {
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'image/png' }),
      )

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'BP.png')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      message.error(t('errorDownload'))
    }
    setBtnLoading(false)
  }

  const { data: salesData = [], isLoading } = useQuery({
    queryKey: ['oneSalesOrder', docEntry],
    queryFn: fetchOneSale,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (salesData?.u_ChertejId) {
      const photoId = salesData?.u_ChertejId

      const photoPaths = photoId
        ? photoId.includes(',')
          ? photoId.split(',')
          : [photoId]
        : []

      const initialPath = photoPaths.map((path) => ({
        file: 'not empty',
        has: true,
        path,
        imgLoading: false,
      }))

      const finalPath =
        initialPath.length > 0
          ? initialPath
          : [{ file: null, path: '', imgLoading: false }]

      setPath(finalPath)
    }
  }, [salesData])

  if (!data) return null

  const columns = [
    {
      title: t('item'),
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      width: 150,
      render: (text, record, index) => {
        return <span>{text}</span>
      },
    },

    {
      title: t('kv.m'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span> {formattedText}</span>
      },
    },

    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },

    {
      title: t('totalMiqdor'),
      dataIndex: 'lineTotal',
      key: 'lineTotal',
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat('fr-FR').format(text)
        return <span>{formattedText} USD</span>
      },
    },
    {
      title: t('u_Produkt'),
      dataIndex: 'u_Produkt',
      key: 'u_Produkt',
    },
  ]

  const tableData = salesData?.documentLines?.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    price: item.price,
    lineTotal: item.lineTotal,
    whsName: item.whsName,
    u_Produkt: item.u_Produkt,
  }))

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('order')} № {salesData.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="90%"
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-bold">{t('mijoz')}</p>
              <Input
                type="text"
                value={salesData.cardName}
                className="flex w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('status')}</p>
              <Input
                value={salesData?.u_AbsoluteStatus}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('currency')}</p>
              <Input
                value={salesData?.docCurrency}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('date')}</p>
              <Input
                type="text"
                value={moment(salesData?.docDate).format('YYYY-MM-DD')}
                style={{ color: 'black' }}
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            loading={isLoading}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: '100%' }}
            bordered
          />
          <div className="flex w-full items-center gap-5 mt-5">
            {path.map((image, index) =>
              image.has
                ? image.path && (
                    <div
                      key={index}
                      className="flex w-full items-start flex-col gap-3 "
                    >
                      <div>
                        <p className="font-bold">
                          {t('photo')}-{index + 1}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <Button
                          onClick={() => viewImage(index)}
                          loading={btnLoading}
                          className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
                        >
                          {t('download')}{' '}
                        </Button>
                      </div>
                    </div>
                  )
                : '',
            )}
          </div>

          <div className="w-full flex items-center justify-between mt-5  ">
            <div className="flex items-center gap-3 ">
              <p className="font-bold">{t('totalForDocument')}:</p>
              <Input
                type="text"
                value={`${new Intl.NumberFormat('fr-FR').format(salesData.docTotal)} ${salesData.docCurrency}`}
                className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
                style={{ color: 'black' }}
                disabled
              />
            </div>
          </div>
        </div>
        <OrderPaymentModal
          visible={isModalVisible}
          data={data}
          onClose={() => setIsModalVisible(false)}
        />
        <SuccessModal
          getRef={(r) => {
            successRef.current = r
          }}
        />
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </div>
    </Modal>
  )
}

export default ViewExpectedPaymentsModal
