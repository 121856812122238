import { Modal, Input, Select, Button } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import {
  fetchRoles,
  fetchDepartments,
  fetchCashAccounts,
  fetchWarehouseSelect,
  fetchSalesPerson,
} from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import DeleteIcon from '../../assets/icons/DeleteSvg'
const EditEmployeesModal = ({ visible, data, onClose, refetch }) => {
  const initItem = {
    roleId: '',
    roleName: '',
  }

  const { t } = useTranslation()

  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [slpCode, setSlpCode] = useState('')
  const [department, setDepartment] = useState('')
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [uzsCash, setUzsCash] = useState('')
  const [usdCash, setUsdCash] = useState('')
  const [terminalCash, setTerminalCash] = useState('')
  const [whs, setWhs] = useState('')
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (data) {
      setName(`${data.firstName ?? ''} ${data.lastName ?? ''}`)
      setPhoneNumber(data.mobilePhone || '')
      setSlpCode(data.salesPersonCode)
      setDepartment(data.department)
      setLogin(data.employeeCode)
      setPassword(data.externalEmployeeNumber)
      setUzsCash(data.u_KassirKassa_UZS)
      setUsdCash(data.u_KassirKassa_USD)
      setTerminalCash(data.u_KassirTerminal)
      setWhs(data.u_WareHouse)
      setRoles(data.roleLines)
    }
  }, [data])

  const { data: rolesData = [] } = useQuery({
    queryKey: ['roles'],
    queryFn: fetchRoles,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: departmentData = [] } = useQuery({
    queryKey: ['departments'],
    queryFn: fetchDepartments,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: cashAccs = [] } = useQuery({
    queryKey: ['cashAccounts'],
    queryFn: fetchCashAccounts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: whsData = [] } = useQuery({
    queryKey: ['whs'],
    queryFn: fetchWarehouseSelect,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: slpData = [] } = useQuery({
    queryKey: ['salesPerson'],
    queryFn: fetchSalesPerson,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleAddItem = () => {
    setRoles((prev) => [...prev, { ...initItem }])
  }

  const handleRemoveItem = (i) => {
    setRoles((prev) => prev.filter((_, idx) => idx !== i))
  }

  const handleRoleChange = (role, label, i) => {
    const updatedRoles = [...roles]
    roles[i].roleId = role
    roles[i].roleName = label.label
    setRoles(updatedRoles)
  }

  const patchData = async () => {
    setLoading(true)
    const employeeRolesInfoLines = roles.map((role, idx) => {
      return {
        employeeID: data.employeeId,
        roleID: role.roleId,
        lineNum: idx,
      }
    })

    try {
      await http.patch(`api/users/update/${data.employeeId}`, {
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1],
        mobilePhone: phoneNumber,
        salesPersonCode: slpCode,
        department: department,
        employeeCode: login,
        externalEmployeeNumber: password,
        u_KassirKassa_UZS: uzsCash,
        u_KassirKassa_USD: usdCash,
        u_KassirTerminal: terminalCash,
        u_WareHouse: whs,
        employeeRolesInfoLines,
      })
      successRef.current.open(t('sucEdited'))
      onClose()
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  if (!data) return null

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('infoEmployees')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="55%"
      style={{ height: '80%' }}
      bodyStyle={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center gap-7 justify-between sm:flex-row">
            <div className="flex flex-col gap-3 w-full">
              <p className="font-nunito">{t('name')}</p>
              <Input
                type="text"
                value={name}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </div>

            <div className="flex flex-col gap-3 w-full">
              <p className="font-nunito">{t('phoneNum')}</p>
              <Input
                type="text"
                value={phoneNumber}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center gap-7  justify-between sm:flex-row">
            <div className="flex flex-col gap-3 w-full">
              <p className="font-nunito">{t('loginName')}</p>
              <Input
                type="text"
                value={login}
                className="flex h-10 w-auto items-center justify-center"
                style={{ color: 'black' }}
                onChange={(e) => {
                  setLogin(e.target.value)
                }}
              />
            </div>
            <div className="flex flex-col gap-3 w-full">
              <p className="font-nunito">{t('Password')}</p>
              <Input
                type="text"
                value={password}
                className="flex h-10  w-auto items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center gap-7  justify-between sm:flex-row">
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('department')}</p>
              <Select
                value={department}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setDepartment(val)
                }}
                options={departmentData.map((department) => {
                  return {
                    label: department.name,
                    value: Number(department.code),
                  }
                })}
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('slpName')}</p>
              <Select
                value={slpCode}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setSlpCode(val)
                }}
                options={slpData.map((slp) => {
                  return {
                    label: slp.slpName,
                    value: Number(slp.slpCode),
                  }
                })}
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center gap-7  justify-between sm:flex-row">
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('SumCash')}</p>
              <Select
                value={uzsCash}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setUzsCash(val)
                }}
                options={cashAccs
                  .filter((cash) => {
                    return cash.actCurr === 'UZS'
                  })
                  .map((cash) => {
                    return {
                      label: `${cash.acctCode} - ${cash.acctName}`,
                      value: cash.acctCode,
                    }
                  })}
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('dollarCash')}</p>
              <Select
                value={usdCash}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setUsdCash(val)
                }}
                options={cashAccs
                  .filter((cash) => {
                    return cash.actCurr === 'USD'
                  })
                  .map((cash) => {
                    return {
                      label: `${cash.acctCode} - ${cash.acctName}`,
                      value: cash.acctCode,
                    }
                  })}
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center gap-7  justify-between sm:flex-row">
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('terminalCash')}</p>
              <Select
                value={terminalCash}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setTerminalCash(val)
                }}
                options={cashAccs
                  .filter((cash) => {
                    return cash.actCurr === 'UZS'
                  })
                  .map((cash) => {
                    return {
                      label: `${cash.acctCode} - ${cash.acctName}`,
                      value: cash.acctCode,
                    }
                  })}
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
              <p className="font-nunito">{t('whs')}</p>
              <Select
                value={whs}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setWhs(val)
                }}
                options={whsData?.map((whs) => {
                  return {
                    label: whs.warehouseName,
                    value: whs.warehouseCode,
                  }
                })}
              />
            </div>
          </div>

          <table className="w-full text-sm m-5 text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th className="px-6 py-4 border text-center">{t('roles')}</th>

                <th className="px-6 py-4 border text-center"></th>
              </tr>
            </thead>
            <tbody>
              {roles.map((v, i) => (
                <tr className="bg-white hover:bg-gray-50 items-center" key={i}>
                  <td className="px-6 border py-4 w-full">
                    <Select
                      value={v.roleId}
                      className="flex h-10  w-full items-center justify-center "
                      style={{ color: 'black' }}
                      onChange={(val, label) => {
                        handleRoleChange(val, label, i)
                      }}
                      options={rolesData.map((role) => {
                        return {
                          label: role.roleName,
                          value: role.roleId,
                        }
                      })}
                    />
                  </td>

                  <td className="px-6 border py-4">
                    <div className="flex items-center justify-start gap-2 w-[100px]">
                      {roles.length > 1 && (
                        <button
                          onClick={() => handleRemoveItem(i)}
                          className="bg-[#d62a2a] text-white w-[30px] h-[35px] rounded-lg flex justify-center items-center"
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      {i === roles.length - 1 && (
                        <Button type="primary" onClick={handleAddItem}>
                          +
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex items-center gap-10 mt-14 justify-end">
            <Button
              onClick={patchData}
              loading={loading}
              disabled={loading}
              className="w-auto bg-[#0A4D68] h-[35px] rounded-lg text-white"
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
        onConfirm={() => onClose()}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default EditEmployeesModal
