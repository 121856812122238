import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { session } from '../../services/session'
import { logout } from '../../slice/mainSlice'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import Menubar from '../Menu'
import { Select, Typography, Drawer, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { get } from 'lodash'

const { Option } = Select

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const role = useSelector((state) => state.main.role)
  const terminalCash = useSelector((state) => state.main.terminalCash)
  const uzsCash = useSelector((state) => state.main.uzsCash)
  const usdCash = useSelector((state) => state.main.usdCash)
  const user = useSelector((state) => state.main.user)
  const getMe = useSelector((state) => state.main.getMe)
  const roleNames = useSelector((state) => state.main.roleNames)

  const [clicked, setClicked] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language')
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage)
    }
  }, [i18n])
  const handleClick = () => {
    navigate('/auth')
    session.delete()
    dispatch(logout())
  }

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value)
    localStorage.setItem('language', value)
  }

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div
        className="h-screen w-full overflow-y-auto"
        onClick={() => {
          if (clicked) setClicked(false)
        }}
      >
        <div className="relative flex h-[80px] w-full items-center justify-end gap-4 bg-[#FFFFFF] pr-10 drop-shadow-xl">
          <Select
            className=" w-32 ml-5"
            defaultValue={i18n.language}
            onChange={handleLanguageChange}
            style={{ width: 120 }}
          >
            <Option value="en">
              <span className="flex items-center">
                <Flag code="GB" className="mr-2" style={{ width: 20 }} />
                EN
              </span>
            </Option>
            <Option value="ru">
              <span className="flex items-center">
                <Flag code="RU" className="mr-2" style={{ width: 20 }} />
                RU
              </span>
            </Option>
            <Option value="uzb">
              <span className="flex items-center">
                <Flag code="UZ" className="mr-2" style={{ width: 20 }} />
                UZB
              </span>
            </Option>
          </Select>

          <div
            className="flex items-center gap-3"
            onClick={(event) => {
              event.stopPropagation()
              setClicked(!clicked)
              setDrawerVisible(!drawerVisible)
            }}
          >
            <div className="ml-10 flex h-8 w-8 items-center justify-center rounded-full bg-[#0085FF1A] text-[#0085FF]">
              {user[0]}
            </div>
            <h1 className="font-inter text-base font-medium">{user}</h1>
            <Drawer
              title={
                <div className="text-xl font-semibold  text-black">
                  {`${user}`}
                </div>
              }
              placement="right"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
              width={300}
              className="dark:bg-gray-800"
              closeIcon={
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => setDrawerVisible(false)}
                  className="text-black "
                />
              }
            >
              <div className="space-y-2">
                {[
                  {
                    label: t('phoneNum'),
                    value: get(getMe, 'mobilePhone', ''),
                  },
                  {
                    label: t('dollarCash'),
                    value: usdCash,
                  },

                  {
                    label: t('SumCash'),
                    value: uzsCash,
                  },
                  {
                    label: t('terminal'),
                    value: terminalCash,
                  },
                  {
                    label: t('whs'),
                    value: get(getMe, 'u_WareHouse', ''),
                  },
                  {
                    label: t('departmentName'),
                    value: get(getMe, 'departmentName', ''),
                  },
                  {
                    label: t('roles'),
                    value: get(getMe, 'roleLines', '')
                      ? get(getMe, 'roleLines', '').map((item) => {
                          return item.roleName + ', '
                        })
                      : '',
                  },

                  { label: t('jobTitle'), value: role },
                ].map((item, index) => (
                  <div key={index} className="flex justify-between">
                    <Typography.Text type="secondary">
                      {item.label}
                    </Typography.Text>
                    <Typography.Text className="text-black">
                      {item.value}
                    </Typography.Text>
                  </div>
                ))}
              </div>
              <Button
                type="primary"
                danger
                icon={<FaSignOutAlt />}
                className="mt-4 w-full"
                onClick={handleClick}
              >
                {t('logout')}
              </Button>
            </Drawer>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Layout
