import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnCashs } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useQuery } from '@tanstack/react-query'
import { fetchCashAccounts } from '../../../utils/fetchData'

const Cashs = () => {
  const { t } = useTranslation()

  const { data = [], isLoading } = useQuery({
    queryKey: ['cashAccounts'],
    queryFn: fetchCashAccounts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const columns = columnCashs(t)

  return (
    <Layout>
      <Header
        title={'cashs'}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
      />
    </Layout>
  )
}

export default Cashs
