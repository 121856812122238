import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnLandedCosts } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import ViewIncomingPaymentModal from '../../../components/Modal/viewIncomingPaymentModal'
import CreateIncomingPaymentModal from './Create'
import { useQuery } from '@tanstack/react-query'
import { fetchLandedCosts } from '../../../utils/fetchData'

const LandedCosts = () => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [addModalVisible, setIsAddModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['landedCosts'],
    queryFn: fetchLandedCosts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleAddClick = () => {
    setIsAddModalVisible(true)
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }

  const columns = columnLandedCosts(t, handleRowClick)

  return (
    <Layout>
      <Header
        title={'landedCosts'}
        columns={columns}
        fdata={data}
        loading={isLoading}
        isSticky={false}
        onBtnClick={handleAddClick}
      />
      <ViewIncomingPaymentModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => setIsModalVisible(false)}
        refetch={refetch}
      />
      <CreateIncomingPaymentModal
        visible={addModalVisible}
        onClose={() => {
          setIsAddModalVisible(false)
          refetch()
        }}
      />
    </Layout>
  )
}

export default LandedCosts
