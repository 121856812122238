import { http } from '../services/http'

export const fetchSales = async ({ queryKey }) => {
  const [
    _,
    page,
    cardName,
    docNum,
    startDate,
    endDate,
    slpName,
    department,
    api,
  ] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&docNum=${docNum}&slpName=${slpName}&department=${department}&startDate=${startDate}&endDate=${endDate}&skip=${page}&pageSize=10`
    const { data } = await http.get(url)
    const formattedData = data.data
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchSalesWithPageSize = async ({ queryKey }) => {
  const [
    _,
    page,
    cardName,
    docNum,
    startDate,
    endDate,
    slpName,
    department,
    api,
  ] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&docNum=${docNum}&slpName=${slpName}&department=${department}&startDate=${startDate}&endDate=${endDate}&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    const formattedData = data.data
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchPurchases = async ({ queryKey }) => {
  const [_, page, cardName, docNum, startDate, endDate, slpName, api] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&docNum=${docNum}&slpName=${slpName}&startDate=${startDate}&endDate=${endDate}&pageToken=${page}`
    const { data } = await http.get(url)
    const formattedData = data.data
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchPurchasesWithPageSize = async ({ queryKey }) => {
  const [_, page, cardName, docNum, startDate, endDate, slpName, api] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&docNum=${docNum}&slpName=${slpName}&startDate=${startDate}&endDate=${endDate}&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    const formattedData = data.data
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchOutgoingPayments = async ({ queryKey }) => {
  const [_, page, cardName, docNum, startDate, endDate] = queryKey
  try {
    let url = `api/vendorpayments?cashAcctName=${cardName}&docNum=${docNum}&startDate=${startDate}&endDate=${endDate}&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchDebtors = async ({ queryKey }) => {
  const [_, page, cardName] = queryKey
  try {
    let url = `api/businesspartners/debetors?cardName=${cardName}&pageSize=10&pageToken=${page}`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchClients = async ({ queryKey }) => {
  const [_, page, cardName, cardType, phoneNumber, address] = queryKey
  try {
    let url = `api/businesspartners/mijozlar?cardName=${cardName}&cardType=${cardType}&phoneNumber=${phoneNumber}&address=${address}&pageSize=10&pageToken=${page}`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchClientsSelect = async ({ queryKey }) => {
  const [_, cardName, pageSize, api] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&pageSize=${pageSize}`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchItemsSelect = async ({ queryKey }) => {
  const [_, itemName, category, api] = queryKey
  try {
    let url = `${api}?itemName=${itemName}&category=${category}`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchSuppliersSelect = async ({ queryKey }) => {
  const [_, cardName, pageSize, api] = queryKey
  try {
    let url = `${api}?cardName=${cardName}&pageSize=${pageSize}`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchDdsArticle = async ({ queryKey }) => {
  try {
    let url = `api/incomingpayments/dds-articles`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchCashAccounts = async ({ queryKey }) => {
  try {
    let url = `api/cashaccounts`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchIncomingPayments = async ({ queryKey }) => {
  const [_, page, cardName, docNum, startDate, endDate, acctName] = queryKey
  try {
    let url = `api/incomingpayments?cardName=${cardName}&docNum=${docNum}&acctName=${acctName}&startDate=${startDate}&endDate=${endDate}&pageSize=10&pageToken=${page}`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchWarehouse = async () => {
  try {
    const { data } = await http.get(`api/warehouses`)
    const mappedItems = data.map((item) => ({
      code: item.warehouseCode,
      name: item.warehouseName,
    }))
    return mappedItems
  } catch (err) {
    console.log(err)
  }
}

export const fetchWarehouseSelect = async () => {
  try {
    const { data } = await http.get(`api/warehouses`)

    return data
  } catch (err) {
    console.log(err)
  }
}

export const fetchReports = async ({ queryKey }) => {
  const [_, page, itemName] = queryKey

  try {
    const { data } = await http.get(
      `api/items/inventory-items?itemName=${itemName}&category=oyna&pageToken=${page}&pageSize=30`,
    )
    const formattedData = data.data.map((item, index) => ({
      key: index,
      itemCode: item.itemCode,
      itemName: item.itemName,
      onHandSum: item.onHandSum,
      inventoryItemWhs: item.inventoryItemWhs.map((wItem, wIndex) => ({
        key: wIndex + 1,
        warehouseName: wItem.whsName,
        onHand: wItem.onHand,
        onOrder: wItem.onOrder,
        available: wItem.available,
      })),
    }))
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchReportsWithWhs = async ({ queryKey }) => {
  const [_, page, itemName, whsCode] = queryKey

  try {
    const { data } = await http.get(
      `api/items/items-by-warehouse?whsCode=${whsCode}&category=oyna&itemName=${itemName}&pageSize=30&skip=${page}`,
    )
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchBalance = async ({ queryKey }) => {
  const [_, uzsCash, usdCash] = queryKey

  try {
    const { data } = await http.get(
      `api/cashaccounts?uzsAccount=${uzsCash}&usdAccount=${usdCash}`,
    )
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchItems = async ({ queryKey }) => {
  const [_, itemName, itemCode, page] = queryKey
  try {
    let url = `api/items/inventory-items?itemName=${itemName}&itemCode=${itemCode}&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchItemsGroups = async () => {
  try {
    let url = `api/items/item-groups`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchRoles = async () => {
  try {
    let url = `api/users/roles`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchDepartments = async () => {
  try {
    let url = `api/departments`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchSalesPerson = async () => {
  try {
    const { data } = await http.get(`api/users/sales-employees`)
    return data
  } catch (err) {
    console.log(err)
  }
}

export const fetchItemsGlass = async ({ queryKey }) => {
  const [_, itemName, itemCode, page] = queryKey
  try {
    let url = `api/items/inventory-items?itemName=${itemName}&itemCode=${itemCode}&category=oyna&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchSuppliers = async ({ queryKey }) => {
  const [_, cardName, page] = queryKey
  try {
    let url = `api/businesspartners/yetkazib-beruvchilar?cardName=${cardName}&pageToken=${page}&pageSize=10`
    const { data } = await http.get(url)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchSeeSales = async ({ queryKey }) => {
  const [_, page, cardCode, docNum, startDate, endDate] = queryKey

  try {
    let url = ''

    url = `api/businesspartners/get-bp-documents?cardCode=${cardCode}&docNum=${docNum}&startDate=${startDate}&endDate=${endDate}&pageSize=10&skip=${page}`
    const { data } = await http.get(url)

    const formattedData = data

    const hasMore = formattedData.length === 10 ? true : false
    return formattedData
  } catch (error) {
    console.log(error)
  }
}

export const fetchCostTypes = async () => {
  try {
    const { data } = await http.get('api/landedcosts/cost-types')
    return data
  } catch (error) {
    console.error(error)
  }
}

export const fetchLandedCosts = async () => {
  try {
    const { data } = await http.get('api/landedcosts')
    return data
  } catch (error) {
    console.error(error)
  }
}

export const fetchStockTransfers = async ({ queryKey }) => {
  const [_, page] = queryKey
  try {
    let url = `api/stocktransfers?pageSize=20&skip=${page}`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchItemsStockTransfers = async () => {
  try {
    let url = `api/stocktransfers/items`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchExpectedPayments = async ({ queryKey }) => {
  const [_, page, cardName, startDate, endDate] = queryKey
  try {
    let url = `api/sales/sales-order-open-amounts?cardName=${cardName}&startDate=${startDate}&endDate=${endDate}&pageSize=10&skip=${page}`
    const { data } = await http.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}
