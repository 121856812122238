import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Auth from '../pages/auth'
import PurchaseOrder from '../pages/sales/PurchaseOrder'
import PurchaseInvoices from '../pages/sales/PurchaseInvoices'
import PurchaseIncoming from '../pages/sales/PurchaseIncoming'
import CreatePurchaseOrder from '../pages/sales/PurchaseOrder/createPurchaseOrder'
import Items from '../pages/sales/Items'
import Suppliers from '../pages/sales/Suppliers/index'
import MySales from '../pages/sales/MySales'
import Debtors from '../pages/sales/Debtors'
import FinishedSales from '../pages/sales/FinishedSales'
import ShippedSales from '../pages/sales/ShippedSales'
import Clients from '../pages/sales/Clients'
import SalesOrder from '../pages/sales/SalesOrder'
import CreateSales from '../pages/sales/SalesOrder/Create'
import Sales from '../pages/sales/Sales'
import OutgoingPayments from '../pages/sales/Payments/OutgoingPayments'
import IncomingPayments from '../pages/sales/Payments/IncomingPayments/index'
import BalancePage from '../pages/sales/Cash/Balance/index'
import SendMoney from '../pages/sales/Cash/SendMoney/index'
import Reports from '../pages/sales/Reports/index'
import SeeSales from '../pages/sales/SeeSales'
import OrderPage from '../pages/sales/OrderPage'
import MarshrutPage from '../pages/sales/MarshrutPage/index'
import ItemsAdmin from '../pages/sales/ItemsAdmin'
import StockTransfers from '../pages/sales/StockTranfers/index'
import PackageOfServices from '../pages/sales/PackageOfSerivces/index'
import Employees from '../pages/sales/Employees/index'
import ItemsServices from '../pages/sales/ItemsServices/index'
import ExpectedPayments from '../pages/sales/ExpectedPayments/index'
import Branches from '../pages/sales/Branches/index'
import Cashs from '../pages/sales/Cashs/index'
import LandedCosts from '../pages/sales/LandedCosts/index'
import ItemsTable from '../pages/sales/ItemsTable'
import ItemsServicesTable from '../pages/sales/ItemsServicesTable'
import DebtorsAdmin from '../pages/sales/DebtorsAdmin/index';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated)
  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/auth" replace />
  )
}

const RoutesComponent = () => {
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated)

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? '/my-sales' : '/auth'} replace />
        }
      />
      <Route path="/auth" element={<Auth />} />

      <Route
        path="/purchase-order"
        element={<ProtectedRoute element={PurchaseOrder} />}
      />
      <Route path="/orders" element={<ProtectedRoute element={OrderPage} />} />
      <Route
        path="/marshrut"
        element={<ProtectedRoute element={MarshrutPage} />}
      />
      <Route
        path="/purchase-incoming"
        element={<ProtectedRoute element={PurchaseIncoming} />}
      />
      <Route
        path="/purchase-invoices"
        element={<ProtectedRoute element={PurchaseInvoices} />}
      />
      <Route path="/items" element={<ProtectedRoute element={Items} />} />
      <Route
        path="/itemsAdmin"
        element={<ProtectedRoute element={ItemsAdmin} />}
      />
      <Route
        path="/itemsServices"
        element={<ProtectedRoute element={ItemsServices} />}
      />
      <Route
        path="/expected-payments"
        element={<ProtectedRoute element={ExpectedPayments} />}
      />
      <Route path="/branches" element={<ProtectedRoute element={Branches} />} />
      <Route path="/cashs" element={<ProtectedRoute element={Cashs} />} />
      <Route
        path="/itemsTable"
        element={<ProtectedRoute element={ItemsTable} />}
      />
      <Route
        path="/servicesTable"
        element={<ProtectedRoute element={ItemsServicesTable} />}
      />
      <Route
        path="/landed-costs"
        element={<ProtectedRoute element={LandedCosts} />}
      />

      <Route
        path="/suppliers"
        element={<ProtectedRoute element={Suppliers} />}
      />
      <Route
        path="/create-purchase-order"
        element={<ProtectedRoute element={CreatePurchaseOrder} />}
      />
      <Route
        path="/stock-transfers"
        element={<ProtectedRoute element={StockTransfers} />}
      />
      <Route
        path="/employees"
        element={<ProtectedRoute element={Employees} />}
      />
      <Route
        path="/package-services"
        element={<ProtectedRoute element={PackageOfServices} />}
      />
      <Route
        path="/see-sales"
        element={<ProtectedRoute element={SeeSales} />}
      />
      <Route path="/my-sales" element={<ProtectedRoute element={MySales} />} />
      <Route
        path="/sales-order"
        element={<ProtectedRoute element={SalesOrder} />}
      />
      <Route
        path="/sales-order/add"
        element={<ProtectedRoute element={CreateSales} />}
      />
      <Route path="/debtors" element={<ProtectedRoute element={Debtors} />} />
      <Route path="/debtorsAdmin" element={<ProtectedRoute element={DebtorsAdmin} />} />

      <Route
        path="/finished-sales"
        element={<ProtectedRoute element={FinishedSales} />}
      />
      <Route path="/sales" element={<ProtectedRoute element={Sales} />} />
      <Route
        path="/outgoing-payments"
        element={<ProtectedRoute element={OutgoingPayments} />}
      />
      <Route
        path="/incoming-payments"
        element={<ProtectedRoute element={IncomingPayments} />}
      />
      <Route
        path="/balance"
        element={<ProtectedRoute element={BalancePage} />}
      />
      <Route
        path="/send-money"
        element={<ProtectedRoute element={SendMoney} />}
      />
      <Route path="/reports" element={<ProtectedRoute element={Reports} />} />

      <Route
        path="/shipped-sales"
        element={<ProtectedRoute element={ShippedSales} />}
      />
      <Route path="/clients" element={<ProtectedRoute element={Clients} />} />
    </Routes>
  )
}

export default RoutesComponent
