import { Modal, Input, Select, Button } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { fetchItemsGroups } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
const EditItemsTableModal = ({
  visible,
  data,
  onClose,
  onClick,
  finish = false,
}) => {
  const { t } = useTranslation()

  const successRef = useRef()
  const errorRef = useRef()

  const [loading, setLoading] = useState('')
  const [itemName, setItemName] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [quantity, setQuantity] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState('')
  const [itmsGrp, setItmsGrp] = useState('')

  useEffect(() => {
    if (data) {
      setItemName(data.itemName)
      setWidth(parseFloat(data.u_Width) ?? '')
      setHeight(parseFloat(data.u_Height) ?? '')
      setQuantity(parseFloat(data.u_Kvmetr) ?? '')
      setPrice(parseFloat(data.price) ?? '')
      setCurrency(data.currency ?? '')
      setItmsGrp(data.itmsGrpCod ?? '')
    }
  }, [data])

  const { data: itemsGroupData = [], isLoading: itemsLoading } = useQuery({
    queryKey: ['itemsGrp'],
    queryFn: fetchItemsGroups,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const postData = async () => {
    setLoading(true)

    try {
      await http.patch(`api/items/${data.itemCode}`, {
        itemName,
        itemsGroupCode: itmsGrp,
        price,
        currency,
        u_Width: width.toString(),
        u_Height: height.toString(),
        u_Kvmetr: quantity.toString(),
      })
      successRef.current.open(t('sucEdited'))
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  if (!data) return null

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('infoItem')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="65%"
      style={{ height: '80%' }}
      bodyStyle={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center gap-7 justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t('itemName')}</p>
              <Input
                type="text"
                value={itemName}
                className="flex h-10  w-full items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setItemName(e.target.value)
                }}
              />
            </div>
            {/* <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t('productType')}</p>
              <Input
                value={
                  data.itemType === 'I'
                    ? 'Товар'
                    : data.itemType === 'L'
                      ? 'Работа'
                      : ''
                }
                className="flex h-10  w-auto items-center justify-center "
                style={{ color: 'black' }}
                disabled
              />
            </div> */}
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t('productGroup')}</p>
              <Select
                value={itmsGrp}
                className="flex h-10  w-[200px] items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setItmsGrp(val)
                }}
                options={itemsGroupData.map((item) => {
                  return {
                    label: item.itmsGrpNam,
                    value: Number(item.itmsGrpCod),
                  }
                })}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t('currency')}</p>
              <Select
                value={currency}
                className="flex h-10  w-[200px] items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setCurrency(val)
                }}
                options={[
                  {
                    label: 'USD',
                    value: 'USD',
                  },
                  {
                    label: 'UZS',
                    value: 'UZS',
                  },
                ]}
              />
            </div>
          </div>

          <div className="mt-10 flex flex-col items-center gap-7  justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{`${t('price')} `}</p>
              <Input
                type="number"
                value={price}
                className="flex h-10 w-auto items-center justify-center"
                style={{ color: 'black' }}
                onChange={(e) => {
                  setPrice(e.target.value)
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{`${t('width')} (${t('mm')})`}</p>
              <Input
                type="number"
                value={width}
                className="flex h-10  w-auto items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setWidth(e.target.value)
                }}
              />
            </div>

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{`${t('height')} (${t('mm')})`}</p>
              <Input
                value={height}
                type="number"
                className="flex h-10  w-auto items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setHeight(e.target.value)
                }}
              />
            </div>

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{`${t('quantityN')}`}</p>
              <Input
                value={quantity}
                type="number"
                className="flex h-10  w-auto items-center justify-center "
                style={{ color: 'black' }}
                onChange={(e) => {
                  setQuantity(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="flex items-center gap-10 mt-14 justify-end">
            <Button
              onClick={postData}
              loading={loading}
              disabled={loading}
              className="w-auto bg-[#0A4D68] h-[35px] rounded-lg text-white"
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
        onConfirm={() => onClose()}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default EditItemsTableModal
