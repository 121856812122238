import * as React from 'react'
const OrdersIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 6.58578L8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L11 6.58578M9 10.5858V5.58578M1 18V4C1 2.34315 2.34315 1 4 1H14C15.6569 1 17 2.34315 17 4V15M1 18C1 19.6569 2.34315 21 4 21H14C15.6569 21 17 19.6569 17 18V15M1 18C1 16.3431 2.34315 15 4 15H17"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
)
export default OrdersIcon
