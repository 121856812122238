import * as React from 'react'
const CarIcon = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 17V5M13 17H15M13 17H8M13 5C13 2.79086 11.2091 1 9 1H5C2.79086 1 1 2.79086 1 5V13C1 14.8652 2.27667 16.4323 4.00384 16.875M13 5H16.2091C16.7172 5 17.2063 5.1934 17.577 5.54093L20.3679 8.15739C20.7712 8.53548 21 9.06365 21 9.61646V15C21 16.1046 20.1046 17 19 17M19 17C19 18.1046 18.1046 19 17 19C15.8954 19 15 18.1046 15 17M19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17M8 17C8 18.1046 7.10457 19 6 19C4.89543 19 4 18.1046 4 17C4 16.958 4.00129 16.9163 4.00384 16.875M8 17C8 15.8954 7.10457 15 6 15C4.93742 15 4.06838 15.8286 4.00384 16.875"
      stroke="white"
      strokeWidth={1.5}
    />
  </svg>
)
export default CarIcon
