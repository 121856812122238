import React, { useEffect, useState } from 'react'
import BarcodeGenerator from '../../../components/Barcode/index'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearOrderData } from '../../../slice/mainSlice'
import moment from 'moment'
const OrderPage = () => {
  const dispatch = useDispatch()
  const { ordersPage } = useSelector((state) => state.main)

  const [data, setData] = useState(null)
  const [paidToDate, setPaidToDate] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1000)
  }, [])

  useEffect(() => {
    const storedData = sessionStorage.getItem('orderData')
    if (storedData) {
      setData(JSON.parse(storedData))
      const data = JSON.parse(storedData)
      setPaidToDate(data.paidToDate)

      console.log('ordersData', data)
    }
  }, [])

  useEffect(() => {
    console.log('ordersPage =', ordersPage)
    return () => {
      dispatch(clearOrderData())
    }
  }, [dispatch])

  return (
    <div className="p-2 bg-white text-black font-sans">
      <div className="border-b ">
        <div className="flex justify-between items-center">
          <div>
            <BarcodeGenerator docEntry={data?.docEntry} />
          </div>
          <div className="text-sm">
            <span className="font-bold">Менеджер:</span> {data?.slpName}
          </div>
        </div>

        <p className="font-bold text-lg text-center">ЗАКАЗ № {data?.docNum}</p>

        <div className="flex items-center justify-between mb-5 text-sm ">
          <div className="mt-5  flex flex-col gap-3">
            <p className="font-bold">Информация о заказе</p>
            <p>
              <span className="font-bold">Дата:</span>
              {moment(data?.docDate).format('DD.MM.YYYY')}
            </p>
            <p>
              <span className="font-bold">Срок:</span>{' '}
              {moment(data?.docDueDate).format('DD.MM.YYYY')}
            </p>
          </div>

          <div className="text-left  flex flex-col gap-3">
            <p className="mt-2 font-bold">Информация о покупателе</p>
            <p>
              <span className="font-bold">Ф.И.О.:</span>
              {data?.cardName}
            </p>
          </div>
          <div></div>
        </div>
      </div>

      <div className="mb-6 mt-5">
        <h2 className="text-center font-bold mb-2">Товары</h2>
        <table className="w-full border-collapse border border-black text-sm">
          <thead>
            <tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
              <th className="border border-black px-2 py-1">№</th>
              <th className="border border-black px-2 py-1">
                Наименование товара
              </th>
              <th className="border border-black px-2 py-1">Размер</th>
              <th className="border border-black px-2 py-1">Кол-во</th>
              <th className="border border-black px-2 py-1">Кв.м.</th>
              <th className="border border-black px-2 py-1">Коэффициент</th>
            </tr>
          </thead>
          <tbody>
            {data?.documentLines
              .filter((item, idx) => {
                return item.u_Produkt === null
              })
              .map((item, idx) => {
                return (
                  <tr className=" font-bold text-[9px]   print-bg-color">
                    <td className="border border-black  px-2 py-1 text-center">
                      {idx + 1}
                    </td>
                    <td className="border border-black  px-2 py-1">
                      {item.itemDescription}
                    </td>
                    <td className="border text-center border-black  px-2 py-1">
                      {`${item.u_Height} X ${item.u_Width}`}
                    </td>
                    <td className="border border-black  px-2 py-1 text-center">
                      {item.quantity}
                    </td>
                    <td className="border border-black  px-2 py-1 text-center">
                      {item.u_Kvmetr}
                    </td>
                    <td className="border border-black  px-2 py-1 text-center">
                      {item.u_Coefficient}
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <tfoot>
            <tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
              <td
                className="border border-black px-2 py-1 text-end font-semibold"
                colSpan={6}
              >
                {`Итого : ${new Intl.NumberFormat('fr-FR').format(
                  data?.documentLines
                    .filter((item) => item.u_Produkt === null)
                    .reduce((total, item) => total + Number(item.u_Kvmetr), 0),
                )} к.вм `}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="mb-6">
        <h2 className="text-center font-bold mb-2">Услуги</h2>
        <table className="w-full border-collapse border border-black text-sm">
          <thead>
            <tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
              <th className="border border-black px-2 py-1">№</th>
              <th className="border border-black px-2 py-1">
                Наименование услуги
              </th>
              <th className="border border-black px-2 py-1">Размер</th>
              <th className="border border-black px-2 py-1">Кол-во</th>
              <th className="border border-black px-2 py-1">Цена за ед.</th>
              <th className="border border-black px-2 py-1">Сумма</th>
            </tr>
          </thead>
          <tbody>
            {data?.documentLines
              .filter((item, idx) => {
                return item.u_Produkt !== null
              })
              .map((item, idx) => {
                return (
                  <tr className=" font-bold text-[9px]   print-bg-color">
                    <td className="border border-black px-2 py-1 text-center">
                      {idx + 1}
                    </td>
                    <td className="border border-black px-2 py-1">
                      {item.itemDescription}
                    </td>
                    <td className="border text-center border-black px-2 py-1">
                      {`${item.u_Height} X ${item.u_Width}`}
                    </td>
                    <td className="border border-black px-2 py-1 text-center">
                      {`${new Intl.NumberFormat('fr-FR').format(item.quantity)}`}
                    </td>
                    <td className="border border-black px-2 py-1 text-center">
                      {`${new Intl.NumberFormat('fr-FR').format(item.price)} ${item.currency}`}
                    </td>
                    <td className="border border-black px-2 py-1 text-center">
                      {`${new Intl.NumberFormat('fr-FR').format(item.lineTotal)} ${item.currency}`}
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <tfoot>
            <tr className="print:bg-[#81857B] text-white font-bold text-[9px] bg-[#81857B]  print-bg-color">
              <td
                className="border border-black px-2 py-1 text-end font-semibold"
                colSpan={6}
              >
                {`Итого: ${new Intl.NumberFormat('fr-FR').format(
                  data?.documentLines
                    .filter((item) => item.u_Produkt !== null)
                    .reduce((total, item) => total + item.lineTotal, 0),
                )} ${data?.documentLines[0].currency}`}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="mt-8 border-t pt-6">
        <div className="flex justify-end">
          <table className="border border-black text-sm w-1/3">
            <tbody>
              <tr>
                <td className="print:bg-[#81857B] border  border-black text-white px-4 py-2 font-bold text-[9px] bg-[#81857B]  print-bg-color">
                  Общая сумма (с учетом коэффициентов):
                </td>
                <td className="border text-[9px] font-bold border-black px-4 py-2">
                  {`${new Intl.NumberFormat('fr-FR').format(data?.docTotal)}`}{' '}
                  {data?.docCurrency}
                </td>
              </tr>
              <tr>
                <td className="print:bg-[#81857B] border  border-black text-white px-4 py-2 font-bold text-[9px] bg-[#81857B]  print-bg-color">
                  Оплачено:
                </td>
                <td className="border text-[9px] font-bold border-black px-4 py-2">
                  {' '}
                  {new Intl.NumberFormat('fr-FR').format(paidToDate)}{' '}
                  {data?.docCurrency}
                </td>
              </tr>
              <tr>
                <td className="print:bg-[#81857B] border  border-black text-white px-4 py-2 font-bold text-[9px] bg-[#81857B]  print-bg-color">
                  Остаток (долг):
                </td>
                <td className="border text-[9px] font-bold border-black px-4 py-2">
                  {`${new Intl.NumberFormat('fr-FR').format(data?.docTotal - paidToDate)}`}{' '}
                  {data?.docCurrency}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="grid grid-cols-3 gap-4 mt-20 text-sm ">
          <div className="text-center">
            <div className="border-t border-black w-3/4 mx-auto"></div>
            <p className="mt-2">подпись заказчика</p>
          </div>
          <div className="text-center">
            <div className="border-t border-black w-3/4 mx-auto"></div>
            <p className="mt-2">Товар отпустил</p>
          </div>
          <div className="text-center">
            <div className="border-t border-black w-3/4 mx-auto"></div>
            <p className="mt-2">подпись клиента</p>
          </div>
        </div>

        <div className="mt-12 text-sm">
          <p className="font-bold">КОММЕНТАРИЙ:</p>
          <p className="mt-4 ">
            ПРИМЕЧАНИЕ: После вывоза за пределы территории склада и истечения 3
            дневного срока за остаток стекла клиента, фирма ответственности не
            несет.
          </p>
        </div>
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 opacity-20 text-7xl font-bold pointer-events-none">
        Активный
      </div>
    </div>
  )
}

export default OrderPage
