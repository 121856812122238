import * as React from 'react'
const ViewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    viewBox="0 0 22 16"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M20.13 5.853a3.085 3.085 0 0 1 0 4.294C18.174 12.205 14.816 15 11 15c-3.816 0-7.175-2.795-9.13-4.853a3.085 3.085 0 0 1 0-4.294C3.825 3.795 7.184 1 11 1c3.816 0 7.174 2.795 9.13 4.853Z"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M14 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
)
export default ViewIcon
