import React, { useState, useRef } from 'react'
import Layout from '../../../../components/Layout'
import {
  message,
  Input,
  Select,
  Table,
  Button,
  Modal,
  Checkbox,
  AutoComplete,
} from 'antd'
import { http } from '../../../../services/http'
import TextArea from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CreteCustomerModal from '../../../../components/Modal/createCustomerModal'
import SuccessModal from '../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../components/Modal/ErrorModal'
import moment from 'moment'
import { debouncedSet } from '../../../../utils/debounce'
import { get } from 'lodash'
import {
  fetchClientsSelect,
  fetchItemsSelect,
  fetchDepartments,
  fetchSalesPerson,
  fetchWarehouse,
} from '../../../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import CopyIcon from '../../../../assets/icons/CopySvg'
import ConfirmExitModal from '../../../../components/Modal/ConfirmExitModal/index'
import ConfirmExitModal2 from '../../../../components/Modal/ConfirmExitModal2/index'

const calculateTotalWithKoef = (width, height, quantity, koef = 1) => {
  return ((width * height) / 1000000) * parseFloat(quantity || 0) * koef
}

const calculateLineTotal = (basePrice, servicesTotal, totalWithKoef) => {
  return (parseFloat(basePrice) + parseFloat(servicesTotal)) * totalWithKoef
}

const CreateSales = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const successRef = useRef()
  const errorRef = useRef()

  const { roleNames, getMe, slpCode, department } = useSelector(
    (state) => state.main,
  )

  const [clientCode, setClientCode] = useState('')
  const [clientName, setClientName] = useState('')
  const [searchClientName, setSearchClientName] = useState('')
  const [searchItemName, setSearchItemName] = useState('')
  const [date, setDate] = useState('')
  const [currency, setCurrency] = useState('UZS')
  const [comments, setComments] = useState('')
  const [sendFile, setSendFile] = useState(false)
  const [imageList, setImageList] = useState([
    { file: null, path: '', imgLoading: false },
  ])
  const [whs, setWhs] = useState(get(getMe, 'u_WareHouse', ''))
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isConfirmServiceModalVisible, setIsConfirmServiceModalVisible] =
    useState(false)
  const [btnLoading, setIsBtnLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedServices, setSelectedServices] = useState([])
  const [currentRecord, setCurrentRecord] = useState(null)
  const [departmentAdmin, setDepartmentAdmin] = useState(department)
  const [slpAdmin, setSlpAdmin] = useState(slpCode)
  const [dateSale, setDateSale] = useState(moment().format('YYYY-MM-DD'))
  const [tableData, setTableData] = useState([
    {
      key: '1',
      item: null,
      itemName: null,
      width: '',
      height: '',
      quantity: '',
      ugpName: '',
      price: '',
      currency: currency,
      lineTotal: '',
      lineNum: 0,
    },
  ])

  const {
    data: clientsData = [],
    isLoading: loadingClient,
    refetch,
  } = useQuery({
    queryKey: [
      'clients',
      searchClientName,
      '20',
      'api/businesspartners/mijozlar',
    ],
    queryFn: fetchClientsSelect,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
    queryKey: ['items', searchItemName, 'oyna', 'api/items/get-items'],
    queryFn: fetchItemsSelect,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: serviceItemsData = [] } = useQuery({
    queryKey: ['itemsService', '', 'услуги', 'api/items/get-items'],
    queryFn: fetchItemsSelect,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: warehouseData = [] } = useQuery({
    queryKey: ['warehouses'],
    queryFn: fetchWarehouse,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: departmentData = [] } = useQuery({
    queryKey: ['departments'],
    queryFn: fetchDepartments,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: slpData = [] } = useQuery({
    queryKey: ['salesPerson'],
    queryFn: fetchSalesPerson,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const onSubmit = async () => {
    const photoId = (imageList || [])
      .map((image) => image.path)
      .filter(Boolean)
      .join(',')

    setIsBtnLoading(true)
    console.log('selectedServices = ', selectedServices)
    console.log('tableData = ', tableData)
    try {
      const data = {
        cardCode: clientCode,
        docDate: dateSale,
        docDueDate: date,
        documentOwner: get(getMe, 'employeeId', ''),
        salesPersonCode: slpAdmin,
        comments: comments,
        u_ChertejId: photoId,
        docCurrency: currency,
        department: departmentAdmin,
        documentLines: [
          ...(Array.isArray(tableData)
            ? tableData.map((row, index) => ({
                lineNum: Number(row.lineNum),
                itemCode: row.item,
                quantity:
                  Number(row.quantity * ((row.width * row.height) / 1000000)) *
                    row.koefitsent || 1,
                unitPrice: Number(row.price),
                currency: row.currency,
                warehouseCode: whs,
                u_uslugaID: Number(row.lineNum),
                u_Produkt: null,
                u_Quantity: row.quantity,
                u_Width: row.width,
                u_Height: row.height,
                u_Coefficient: row.koefitsent,
                u_Kvmetr:
                  Number(row.quantity * ((row.width * row.height) / 1000000)) *
                    row.koefitsent || 1,
              }))
            : []),

          ...(Array.isArray(tableData)
            ? tableData
                .filter((row) => Array.isArray(row.selectedServices))
                .flatMap((row, rowIndex) =>
                  row.selectedServices.map((service, serviceIndex) => ({
                    lineNum: tableData.length + rowIndex + serviceIndex,
                    itemCode: service.itemCode,
                    quantity:
                      service.ugpName === 'шт'
                        ? service.quantity
                        : Number(
                            service.quantity *
                              ((tableData[rowIndex].width *
                                tableData[rowIndex].height) /
                                1000000) *
                              tableData[rowIndex].koefitsent || 1,
                          ),
                    unitPrice: Number(service.price),
                    currency: service.currency,
                    warehouseCode: whs,
                    u_uslugaID: Number(service.lineNum),
                    u_Produkt: tableData[rowIndex].itemName,
                    u_Quantity: service.quantity,
                    u_Width:
                      service.ugpName === 'шт' ? '' : tableData[rowIndex].width,
                    u_Height:
                      service.ugpName === 'шт'
                        ? ''
                        : tableData[rowIndex].height,
                    u_Coefficient:
                      service.ugpName === 'шт'
                        ? ''
                        : tableData[rowIndex].koefitsent,
                    u_Kvmetr:
                      service.ugpName === 'шт'
                        ? ''
                        : Number(
                            service.quantity *
                              ((tableData[rowIndex].width *
                                tableData[rowIndex].height) /
                                1000000) *
                              tableData[rowIndex].koefitsent || 1,
                          ),
                  })),
                )
            : []),
        ],
      }

      await http.post(
        `api/sales/sotuv-buyurtmasi?needToConfirmFromBot=${sendFile}`,
        data,
      )
      successRef.current.open(t('sucCreated'))
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
      console.log(error)
    } finally {
      setIsBtnLoading(false)
    }
  }

  const handleKoefitsentChange = (value, key) => {
    setTableData((prevData) => {
      const koef = value || 1
      return prevData.map((row) => {
        if (row.key === key) {
          const selectedPrices = selectedServices.reduce((acc, service) => {
            if (service.ugpName !== 'шт') {
              return acc + service.price
            }
            return acc
          }, 0)

          const shtPrices = selectedServices.reduce((acc, service) => {
            if (service.ugpName === 'шт') {
              return acc + service.price * service.quantity
            }
            return acc
          }, 0)
          // const selectedServicesTotal = getSelectedServicesTotal(row.selectedServices)
          const totalWithKoef = calculateTotalWithKoef(
            row.width,
            row.height,
            row.quantity,
            koef,
          )

          const lineTotal =
            calculateLineTotal(row.price, selectedPrices, totalWithKoef) +
            shtPrices
          return {
            ...row,
            koefitsent: parseFloat(value) || '',
            lineTotal: lineTotal,
          }
        }
        return row
      })
    })
  }

  const addRow = () => {
    setTableData([
      ...tableData,
      {
        key: Date.now(),
        item: null,
        itemName: null,
        width: '',
        height: '',
        quantity: '',
        ugpName: '',
        price: '',
        lineTotal: '',
        lineNum: tableData[tableData.length - 1].lineNum + 1,
      },
    ])
  }

  const copyRow = (record) => {
    console.log('copyRow = ', record)
    setTableData([
      ...tableData,
      {
        key: Date.now(),
        item: record.item,
        itemName: record.itemName,
        width: '',
        height: '',
        quantity: '',
        ugpName: '',
        currency: currency,
        price: record.price,
        lineTotal: '',
        lineNum: tableData[tableData.length - 1].lineNum + 1,
      },
    ])
  }

  const removeRow = (key) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((row) => row.key !== key))
    }
  }

  const handleDateChange = (e) => {
    const input = Number(e.target.value)

    if (!isNaN(input)) {
      let daysToAdd = input
      let newDate = moment()

      while (daysToAdd > 0) {
        newDate.add(1, 'days')
        if (newDate.day() !== 0) {
          daysToAdd--
        }
      }
      setDate(newDate.format('YYYY-MM-DD'))
    } else {
      console.error('Invalid input: Please input a valid number.')
    }
  }

  const handleFileChange = (event, index) => {
    const updatedImageList = [...imageList]
    updatedImageList[index].file = event.target.files[0]
    setImageList(updatedImageList)
  }

  const uploadImg = async (index) => {
    const image = imageList[index]

    if (!image.file) {
      message.error('Фотография не выбрано')
      return
    }

    const formData = new FormData()
    formData.append('file', image.file)

    const generateRandomKey = () => {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let randomKey = ''
      for (let i = 0; i < 16; i++) {
        randomKey += characters.charAt(
          Math.floor(Math.random() * characters.length),
        )
      }
      return randomKey
    }

    const randomKey = generateRandomKey()
    const title = 'YourTitle'

    formData.append('Title', title)
    formData.append('Key', randomKey)

    try {
      const updatedImageList = [...imageList]
      updatedImageList[index].imgLoading = true
      setImageList(updatedImageList)

      const res = await http.post('api/assets/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const { key } = res.data
      updatedImageList[index].path = key
      setImageList(updatedImageList)

      message.success('Успешно загружено!')
    } catch (err) {
      message.error('Ошибка загрузки')
    } finally {
      const updatedImageList = [...imageList]
      updatedImageList[index].imgLoading = false
      setImageList(updatedImageList)
    }
  }

  const viewImage = async (index) => {
    const image = imageList[index]

    try {
      const res = await http.get(`api/assets/download/${image.path}`, {
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: 'image/png',
        }),
      )

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'BP.png')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      message.error('Ошибка загрузки файла')
    }
  }

  const addNewRow = () => {
    setImageList([...imageList, { file: null, path: '', imgLoading: false }])
  }

  const deleteRow = (index) => {
    setImageList(imageList.filter((_, i) => i !== index))
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setSearchClientName)
  }

  const handleItemNameChange = (val) => {
    debouncedSet(val, setSearchItemName)
  }

  const handleModalYes = () => {
    setIsConfirmModalVisible(false)
    navigate('/sales-order')
  }

  const handleModalNo = () => {
    setIsConfirmModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsConfirmModalVisible(false)
  }

  const handleServiceModalYes = () => {
    setIsConfirmServiceModalVisible(false)
    setIsModalOpen(false)
  }

  const handleServiceModalNo = () => {
    setIsConfirmServiceModalVisible(false)
  }

  const handleServiceModalCancel = () => {
    setIsConfirmServiceModalVisible(false)
  }

  const columns = [
    {
      title: t('item'),
      dataIndex: 'item',
      key: 'item',
      fixed: 'left',
      width: 200,
      render: (_, record) => (
        <AutoComplete
          loading={itemsLoading}
          options={itemsData?.map((item) => ({
            value: `${item.itemName} ,/ ${item.itemCode}`,
            label: item.itemName,
          }))}
          className="w-full"
          value={record.itemName}
          onSelect={(value) => {
            const selectedOption = itemsData?.find((item) => {
              const itemName = get(item, 'itemName', '')
              const itemCode = get(item, 'itemCode', '')

              return `${itemName} ,/ ${itemCode} === value`
            })
            if (selectedOption) {
              const itemCode = get(selectedOption, 'itemCode', '')
              const selectedItem = itemsData?.find(
                (item) => `${item.itemName} ,/ ${item.itemCode}` === value,
              )
              if (selectedItem) {
                const updatedData = tableData.map((row) =>
                  row.key === record.key
                    ? {
                        ...row,
                        item: itemCode,
                        itemName: selectedItem.itemName || '',
                        width: selectedItem.BWdth1 || '',
                        height: selectedItem.BHeight1 || '',
                        quantity: '',
                        ugpName: selectedItem.ugpName || '',
                        price: selectedItem.price || '',
                        currency: selectedItem.currency || '',
                        lineTotal: '',
                      }
                    : row,
                )
                setTableData(updatedData)
                message.success(itemCode)
              }
            }
          }}
          onChange={(val) => {
            const value = val.split(' ,/ ')[0]
            handleItemNameChange(value)
            const updatedData = tableData.map((row) =>
              row.key === record.key
                ? {
                    ...row,
                    itemName: value || '',
                  }
                : row,
            )
            setTableData(updatedData)
          }}
          showSearch
        />
      ),
    },
    {
      title: `${t('width')} (${t('mm')})`,
      dataIndex: 'width',
      key: 'width',
      width: 150,
      render: (_, record) => (
        <Input
          value={record.width}
          className={'w-full'}
          onChange={(e) => {
            const updatedData = tableData.map((row) => {
              if (row.key === record.key) {
                const width = e.target.value
                const selectedPrices = selectedServices.reduce(
                  (acc, service) => {
                    if (service.ugpName !== 'шт') {
                      return acc + service.price
                    }
                    return acc
                  },
                  0,
                )

                const shtPrices = selectedServices.reduce((acc, service) => {
                  if (service.ugpName === 'шт') {
                    return acc + service.price * service.quantity
                  }
                  return acc
                }, 0)
                const koef = row.koefitsent || 1
                const totalWithKoef = calculateTotalWithKoef(
                  width,
                  row.height,
                  row.quantity,
                  koef,
                )
                const lineTotal =
                  calculateLineTotal(row.price, selectedPrices, totalWithKoef) +
                  shtPrices

                return {
                  ...row,
                  width,
                  lineTotal,
                }
              }
              return row
            })
            setTableData(updatedData)
          }}
        />
      ),
    },
    {
      title: `${t('height')} (${t('mm')})`,
      dataIndex: 'height',
      key: 'height',
      width: 150,
      render: (_, record) => (
        <Input
          value={record.height}
          className={'w-full'}
          onChange={(e) => {
            const updatedData = tableData.map((row) => {
              if (row.key === record.key) {
                const height = e.target.value
                const selectedPrices = selectedServices.reduce(
                  (acc, service) => {
                    if (service.ugpName !== 'шт') {
                      return acc + service.price
                    }
                    return acc
                  },
                  0,
                )

                const shtPrices = selectedServices.reduce((acc, service) => {
                  if (service.ugpName === 'шт') {
                    return acc + service.price * service.quantity
                  }
                  return acc
                }, 0)
                const koef = row.koefitsent || 1
                const totalWithKoef = calculateTotalWithKoef(
                  row.width,
                  height,
                  row.quantity,
                  koef,
                )
                const lineTotal =
                  calculateLineTotal(row.price, selectedPrices, totalWithKoef) +
                  shtPrices

                return {
                  ...row,
                  height,
                  lineTotal,
                }
              }
              return row
            })

            setTableData(updatedData)
          }}
        />
      ),
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (_, record) => (
        <Input
          value={record.quantity}
          className={'w-full'}
          onChange={(e) => {
            const updatedData = tableData.map((row) => {
              if (row.key === record.key) {
                const quantity = e.target.value
                const selectedPrices = selectedServices.reduce(
                  (acc, service) => {
                    if (service.ugpName !== 'шт') {
                      return acc + service.price
                    }
                    return acc
                  },
                  0,
                )

                const shtPrices = selectedServices.reduce((acc, service) => {
                  if (service.ugpName === 'шт') {
                    return acc + service.price * service.quantity
                  }
                  return acc
                }, 0)
                const koef = row.koefitsent || 1
                const totalWithKoef = calculateTotalWithKoef(
                  row.width,
                  row.height,
                  quantity,
                  koef,
                )
                const lineTotal =
                  calculateLineTotal(row.price, selectedPrices, totalWithKoef) +
                  shtPrices

                return {
                  ...row,
                  quantity,
                  lineTotal,
                }
              }
              return row
            })
            setTableData(updatedData)
          }}
        />
      ),
    },
    {
      title: t('kv.m'),
      dataIndex: 'ugpName',
      key: 'ugpName',
      width: 150,
      render: (_, record) => {
        const koef = record.koefitsent || 1
        const totalWithKoef =
          ((record.width * record.height) / 1000000) *
          parseFloat(record.quantity || 0) *
          koef
        return (
          <span className="w-full">
            {new Intl.NumberFormat().format(totalWithKoef)}
          </span>
        )
      },
    },
    {
      title: t('pricePerKv'),
      dataIndex: 'price',
      key: 'price',
      width: 170,
      render: (_, record) => (
        <Input
          value={record.price}
          className={'w-full'}
          type={'text'}
          onChange={(e) => {
            const updatedData = tableData.map((row) => {
              if (row.key === record.key) {
                const price = e.target.value
                const selectedPrices = selectedServices.reduce(
                  (acc, service) => {
                    if (service.ugpName !== 'шт') {
                      return acc + service.price
                    }
                    return acc
                  },
                  0,
                )

                const shtPrices = selectedServices.reduce((acc, service) => {
                  if (service.ugpName === 'шт') {
                    return acc + service.price * service.quantity
                  }
                  return acc
                }, 0)
                const koef = row.koefitsent || 1
                const totalWithKoef = calculateTotalWithKoef(
                  row.width,
                  row.height,
                  row.quantity,
                  koef,
                )
                const lineTotal =
                  calculateLineTotal(price, selectedPrices, totalWithKoef) +
                  shtPrices
                return {
                  ...row,
                  price,
                  lineTotal,
                }
              }
              return row
            })

            setTableData(updatedData)
          }}
          disabled
        />
      ),
    },
    {
      title: t('services'),
      key: 'services',
      width: 120,
      render: (_, record) => (
        <div className="flexw- full items-center justify-center">
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen(true)
              setCurrentRecord(record) // Store the current row record
              setSelectedServices(record.selectedServices || []) // Populate previously selected services
            }}
          >
            {record.width > 0 || record.height > 0 || record.quantity > 0
              ? new Intl.NumberFormat().format(
                  record.selectedServices
                    ? record.selectedServices.reduce((acc, service) => {
                        if (service.ugpName !== 'шт') {
                          return acc + service.price
                        }
                        return acc
                      }, 0) *
                        (record.quantity *
                          ((record.width * record.height) / 1000000) *
                          (record.koefitsent || 1)) +
                        record.selectedServices.reduce((acc, service) => {
                          if (service.ugpName === 'шт') {
                            return acc + service.price * service.quantity
                          }
                          return acc
                        }, 0)
                    : 0,
                )
              : t('services')}
          </Button>
        </div>
      ),
    },
    {
      title: t('totalMiqdor'),
      dataIndex: 'lineTotal',
      key: 'lineTotal',
      width: 170,
      render: (_, record) => (
        <span>{new Intl.NumberFormat().format(record.lineTotal)}</span>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      width: 100,

      render: (_, record) => (
        <div className="flex gap-2 items-center justify-center">
          <Button type="primary" onClick={addRow}>
            +
          </Button>
          <Button type="primary" onClick={() => copyRow(record)}>
            <CopyIcon />
          </Button>
          <Button
            danger
            type={'primary'}
            onClick={() => removeRow(record.key)}
            disabled={tableData.length === 1}
          >
            -
          </Button>
        </div>
      ),
    },
  ]

  const columns2 = [
    {
      title: t('service'),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: (text) => <span>{text !== null ? text : ''}</span>,
    },
    {
      title: t('ugpName'),
      dataIndex: 'ugpName',
      key: 'ugpName',
      render: (text) => <span>{text !== null ? text : ''}</span>,
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (_, record) =>
        record.ugpName === 'шт' ? (
          <Input
            value={record.quantity}
            className={'w-full'}
            disabled={
              !selectedServices.some(
                (service) => service.itemCode === record.itemCode,
              )
            }
            onChange={(e) => {
              const inputQuantity = e.target.value
              console.log('selectedServices = ', selectedServices)
              const updatedSelectedServices = selectedServices.map(
                (service) => {
                  return {
                    ...service,
                    quantity: inputQuantity,
                  }
                },
              )
              console.log('RecordInput', record)
              setSelectedServices(updatedSelectedServices)
              console.log('updatedSelectedServices = ', updatedSelectedServices)

              const updatedData = tableData.map((row) => {
                if (row.key === record.key) {
                  const selectedServicesTotal = updatedSelectedServices.reduce(
                    (acc, service) => acc + service.price * service.quantity,
                    0,
                  )
                  const koef = row.koefitsent || 1
                  const totalWithKoef = calculateTotalWithKoef(
                    row.width,
                    row.height,
                    inputQuantity,
                    koef,
                  )
                  const lineTotal = calculateLineTotal(
                    row.price,
                    selectedServicesTotal,
                    totalWithKoef,
                  )

                  return {
                    ...row,
                    quantity: inputQuantity,
                    lineTotal,
                  }
                }
                return row
              })

              console.log('updatedData = ', updatedData)

              setTableData(updatedData)
            }}
          />
        ) : (
          ''
        ),
    },
    {
      title: t('Select'),
      dataIndex: 'itemCode',
      key: 'select',
      render: (itemCode) => (
        <Checkbox
          checked={selectedServices.some(
            (service) => service.itemCode === itemCode,
          )}
          onChange={() => toggleServiceSelection(itemCode)}
        />
      ),
    },
  ]

  const toggleServiceSelection = (itemCode) => {
    const selectedService = serviceItemsData.find(
      (service) => service.itemCode === itemCode,
    )
    console.log(selectedService, currentRecord)
    if (selectedService && currentRecord) {
      setSelectedServices((prevSelected) => {
        const isAlreadySelected = prevSelected.some(
          (service) => service.itemCode === itemCode,
        )
        let newSelectedServices

        if (isAlreadySelected) {
          newSelectedServices = prevSelected.filter(
            (service) => service.itemCode !== itemCode,
          )
        } else {
          const { itemCode, price, ugpName } = selectedService
          const { lineNum, currency, quantity } = currentRecord
          console.log('currentRecord', currentRecord)
          newSelectedServices = [
            ...prevSelected,
            { itemCode, price, lineNum, currency, quantity, ugpName },
          ]
        }
        return newSelectedServices
      })
    }
  }

  const handleOk = () => {
    const selectedPrices = selectedServices.reduce((acc, service) => {
      if (service.ugpName !== 'шт') {
        return acc + service.price
      }
      return acc
    }, 0)

    const shtPrices = selectedServices.reduce((acc, service) => {
      if (service.ugpName === 'шт') {
        return acc + service.price * service.quantity
      }
      return acc
    }, 0)

    console.log(selectedPrices, shtPrices)
    console.log(selectedServices, tableData)
    const updatedData = tableData.map((row) => {
      const koef = row.koefitsent || 1
      const totalWithKoef = calculateTotalWithKoef(
        row.width,
        row.height,
        row.quantity,
        koef,
      )
      const lineTotal =
        calculateLineTotal(row.price, selectedPrices, totalWithKoef) + shtPrices
      console.log('totalWithKoef', totalWithKoef)
      console.log('lineTotal', lineTotal)

      return row.key === currentRecord.key
        ? {
            ...row,
            selectedServices,
            lineTotal,
          }
        : row
    })

    setTableData(updatedData)
    setIsModalOpen(false)
  }

  return (
    <Layout>
      <div className={'p-7'}>
        <div className="flex items-center gap-10 flex-wrap">
          <div className={'flex items-end'}>
            <div className="flex flex-col items-start gap-3 ">
              <p className="font-nunito">{t('Client')}</p>
              <AutoComplete
                loading={loadingClient}
                showSearch
                value={clientName}
                className="flex-1 h-10 w-[250px]"
                onSelect={(value) => {
                  const val = value.split(' ,/ ')[0]

                  setClientName(val)
                  handleCardNameChange(val)
                  const selectedOption = clientsData.find((client) => {
                    const cardName = get(client, 'cardName', '')
                    const cardCode = get(client, 'cardCode', '')
                    const phoneNumber = get(client, 'Cellular', '')
                      ? get(client, 'Cellular', '')
                      : ''
                    const formattedValue = [cardName, phoneNumber]
                      .filter(Boolean)
                      .join(' ')
                    return `${formattedValue} ,/ ${cardCode}` === value
                  })

                  if (selectedOption) {
                    const cardCode = get(selectedOption, 'cardCode', '')
                    setClientCode(cardCode)
                    message.success(cardCode)
                  }
                }}
                onChange={(val) => {
                  const value = val.split(' ,/ ')[0]
                  setClientName(value)
                  handleCardNameChange(value)
                }}
                options={clientsData.map((client) => ({
                  value: `${client.cardName}${client.Cellular ? ` ${client.Cellular}` : ''} ,/ ${client.cardCode}`,
                  label: `${client.cardName} ${client.Cellular ? client.Cellular : ''}`,
                }))}
              />
            </div>
            <button
              className="w-[30px] h-8 bg-blue-700 text-white text-lg"
              onClick={() => setIsModalVisible(true)}
            >
              +
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <p>{t('currency')}</p>
            <div className="flex">
              <Select
                value={currency}
                onChange={(value) => setCurrency(value)}
                className="w-[150px]"
                disabled
              >
                <Select.Option key={1} value={'UZS'}>
                  UZS
                </Select.Option>
              </Select>
            </div>
          </div>
          {roleNames.includes('admin') && (
            <div className="flex flex-col gap-2">
              <p>{t('departmentName')}</p>
              <div className="flex">
                <Select
                  value={departmentAdmin}
                  className="flex h-8  w-[150px] items-center justify-center "
                  style={{ color: 'black' }}
                  onChange={(val) => {
                    setDepartmentAdmin(val)
                  }}
                  options={departmentData.map((department) => {
                    return {
                      label: department.name,
                      value: Number(department.code),
                    }
                  })}
                />
              </div>
            </div>
          )}
          {roleNames.includes('admin') && (
            <div className="flex flex-col gap-2">
              <p>{t('slpName')}</p>
              <Select
                value={slpCode}
                className="flex h-8  w-[150px] items-center justify-center "
                style={{ color: 'black' }}
                onChange={(val) => {
                  setSlpAdmin(val)
                }}
                options={slpData.map((slp) => {
                  return {
                    label: slp.slpName,
                    value: Number(slp.slpCode),
                  }
                })}
              />
            </div>
          )}
          {roleNames.includes('admin') && (
            <div className="flex flex-col gap-2">
              <p>{t('date')}</p>
              <Input
                type="date"
                value={dateSale}
                onChange={(e) => setDateSale(e.target.value)}
                className="w-full"
              />
            </div>
          )}
          {roleNames.includes('admin') && (
            <div className="flex flex-col gap-2">
              <p>{t('whs')}</p>
              <Select
                // value={whs}
                onChange={(value) => setWhs(value)}
                className="w-[200px] "
                options={warehouseData
                  .filter((warehouse) => {
                    return warehouse.code !== whs
                  })
                  .map((warehouse) => ({
                    label: warehouse.name,
                    value: warehouse.code,
                  }))}
              />
            </div>
          )}
        </div>
        {/* <div className="flex"></div> */}

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-12 border-black"
          bordered
          scroll={{ x: 'max-content' }}
        />

        <Table
          columns={[
            {
              title: t('item'),
              dataIndex: 'itemName',
              key: 'itemName',
              width: 200,
            },

            {
              title: t('kv.m'),
              dataIndex: 'lineTotalWithKoef',
              key: 'lineTotalWithKoef',
              render: (_, record) => {
                const koef = record.koefitsent || 1
                const totalWithKoef = calculateTotalWithKoef(
                  record.width,
                  record.height,
                  record.quantity,
                  koef,
                )
                return (
                  <span className="w-full">
                    {new Intl.NumberFormat().format(totalWithKoef)}
                  </span>
                )
              },
            },
            {
              title: t('totalMiqdor'),
              dataIndex: 'lineTotal',
              key: 'lineTotal',
              render: (_, record) => {
                const lineTotal = record.lineTotal || 0
                return (
                  <span className="w-full">
                    {new Intl.NumberFormat().format(lineTotal)}
                  </span>
                )
              },
            },
            {
              title: t('Koefitsent'),
              dataIndex: 'koefitsent',
              key: 'koefitsent',
              render: (_, record) => (
                <Input
                  type="number"
                  value={record.koefitsent}
                  onChange={(e) =>
                    handleKoefitsentChange(e.target.value, record.key)
                  }
                  className="w-full"
                />
              ),
            },
          ]}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-2 border-black"
          bordered
          scroll={{ x: 'max-content' }}
        />

        <div className="flex gap-10 mt-20 drop-shadow-lg bg-white p-5 rounded-lg">
          <div className="w-full flex flex-col  gap-6 ">
            <div className="flex flex-col items-start gap-3 ">
              <p className="font-nunito">{t('deadLine')}</p>
              <Input
                type={'number'}
                className={'w-full h-10'}
                onChange={handleDateChange}
              />
            </div>
            <div className="flex gap-7">
              <div className="flex flex-col items-start gap-3">
                <p className="font-nunito">{t('docTotal')}</p>
                <Input
                  type="text"
                  value={`${new Intl.NumberFormat().format(tableData.reduce((acc, row) => acc + Number(row.lineTotal || 0), 0))} ${currency}`}
                  className="h-10 w-full "
                  style={{ color: 'black' }}
                  disabled
                />
              </div>
              <div className="flex flex-col items-start gap-3">
                <p className="font-nunito">{t('totalKvm')}</p>
                <Input
                  type="text"
                  value={`${new Intl.NumberFormat().format(tableData.reduce((acc, row) => acc + (parseFloat(row.quantity * ((row.width * row.height) / 1000000)) * row.koefitsent || 0), 0))}`}
                  className="h-10 w-full "
                  style={{ color: 'black' }}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col items-start gap-3 w-full">
              <p className="font-nunito">{t('sendFile')}</p>
              <Input
                type="checkbox"
                value={sendFile}
                onChange={(e) => {
                  setSendFile(e.target.checked)
                }}
                className="w-fit"
              />
            </div>
          </div>

          <div className="w-full flex  h-full">
            <div className="flex flex-col items-start gap-3 w-full">
              <p className="font-nunito">{t('comments')}</p>
              <TextArea
                className={'w-full'}
                rows={9}
                onChange={(e) => {
                  setComments(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div>
          {imageList.map((image, index) => (
            <div
              key={index}
              className="flex w-full items-start gap-1 flex-col mt-5 mb-10"
            >
              <div>
                <p className="font-bold">
                  {t('photo')}-{index + 1}
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Input
                  type="file"
                  onChange={(e) => handleFileChange(e, index)}
                  className="w-full"
                />

                <Button
                  onClick={() => uploadImg(index)}
                  isLoading={image.imgLoading}
                  className="h-9 w-[200px] rounded-3xl bg-[#0A4D68] text-white"
                >
                  {t('upload')}
                </Button>
                {image.path && (
                  <Button
                    onClick={() => viewImage(index)}
                    className="h-9 w-[200px] rounded-3xl bg-[#0A4D68] text-white"
                  >
                    {t('download')}
                  </Button>
                )}
                {index !== 0 && (
                  <Button
                    onClick={() => deleteRow(index)}
                    className="h-9 w-[70px] rounded-3xl  bg-red-500 text-white"
                  >
                    {/* {t("delete")} */}-
                  </Button>
                )}

                {index === imageList.length - 1 && (
                  <Button
                    onClick={addNewRow}
                    className="h-9 w-[70px] rounded-3xl bg-[#0A4D68] text-white"
                  >
                    {/* {t("add2")} */}+
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={'flex mt-10 w-full items-center gap-10'}>
          <Button
            onClick={() => setIsConfirmModalVisible(true)}
            type={'danger'}
            className="w-full bg-red-600 text-white"
          >
            {t('back')}
          </Button>
          <Button
            onClick={onSubmit}
            loading={btnLoading}
            type={'primary'}
            className="w-full"
          >
            {t('create')}
          </Button>
        </div>
        <CreteCustomerModal
          visible={isModalVisible}
          data={null}
          onClose={() => {
            refetch()
            setIsModalVisible(false)
          }}
          onConfirm={(code) => {
            const data = `${code?.name} ${code?.phoneNumber ? code?.phoneNumber : ''}`
            setClientName(data)
            setClientCode(code?.cardCode)
          }}
        />
      </div>

      <Modal
        title={t('Select Services')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          setIsConfirmServiceModalVisible(true)
        }}
      >
        <Table
          columns={columns2}
          dataSource={serviceItemsData}
          rowKey="itemCode"
          pagination={false}
        />

        <SuccessModal
          getRef={(r) => {
            successRef.current = r
          }}
          onConfirm={() => {
            navigate('/sales-order')
          }}
        />
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </Modal>

      <ConfirmExitModal
        visible={isConfirmModalVisible}
        handleClickCancel={handleModalCancel}
        handleClickNo={handleModalNo}
        handleClickYes={handleModalYes}
      />

      <ConfirmExitModal
        visible={isConfirmServiceModalVisible}
        handleClickCancel={handleServiceModalCancel}
        handleClickNo={handleServiceModalNo}
        handleClickYes={handleServiceModalYes}
      />
    </Layout>
  )
}

export default CreateSales
