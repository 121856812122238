import { Modal, Input, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'

const ViewStockTransfersModal = ({
  visible,
  data,
  onClose,
  onClick,
  ship = false,
  incoming = false,
  purchaseOrder = false,
  refetch,
}) => {
  const { t } = useTranslation()

  const successRef = useRef()
  const errorRef = useRef()

  const [dueDate, setDueDate] = useState('')
  const [status, setStatus] = useState('')
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (data?.docDate) {
      setDueDate(moment(data.docDate).format('YYYY-MM-DD'))
    }
    if (data?.stockTransferLines) {
      setTableData(data.stockTransferLines)
    }
  }, [data])

  if (!data) return null

  const columns = [
    {
      title: t('item'),
      dataIndex: 'itemName',
      key: 'itemName',
      width: 150,
      render: (text, record, index) => {
        return <span>{text}</span>
      },
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record, index) => (
        <span> {new Intl.NumberFormat('fr-FR').format(text)}</span>
      ),
    },
    {
      title: t('measureUnit'),
      dataIndex: 'unitMsr',
      key: 'unitMsr',
    },
    {
      title: t('fromWhs'),
      dataIndex: 'fromWhsName',
      key: 'fromWhsName',
    },
    {
      title: t('toWhs'),
      dataIndex: 'toWhsName',
      key: 'toWhsName',
    },
  ]

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t('order')} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('fromWhs')}</p>
              <Input
                value={data.fromWhsName}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('toWhs')}</p>
              <Input
                value={data.toWhsName}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('department')}</p>
              <Input
                value={data.u_Department}
                style={{ color: 'black' }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-bold">{t('date')}</p>
              <Input
                type="text"
                value={dueDate}
                style={{ color: 'black' }}
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: '100%' }}
            bordered
          />
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default ViewStockTransfersModal
