import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnClient } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import ViewClientsModal from '../../../components/Modal/viewClientsModal'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchClients } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'
import CreteCustomerModal from '../../../components/Modal/createCustomerModal'
import { useSelector } from 'react-redux'

const Clients = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { roleNames } = useSelector((state) => state.main)

  const [currentPage, setCurrentPage] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [cardType, setCardType] = useState('')
  const [cardName, setCardName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'clients',
      currentPage,
      cardName,
      cardType,
      phoneNumber,
      address,
    ],
    queryFn: fetchClients,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleNextPage = () => {
    if (data.length === 10) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleRowClick = (record) => {
    setSelectedRowData(record)
    setIsModalVisible(true)
  }

  const handlePhoneNumberChange = (val) => {
    debouncedSet(val, setPhoneNumber)
  }

  const handleCardNameChange = (val) => {
    debouncedSet(val, setCardName)
  }
  const handleCardTypeChange = (val) => {
    debouncedSet(val, setCardType)
  }
  const handleAddressChange = (val) => {
    debouncedSet(val, setAddress)
  }

  const handleAddClick = () => {
    setIsCreateModalVisible(true)
  }
  const handleSeeBtnClick = (cardCode) => {
    navigate(`/see-sales?cardCode=${encodeURIComponent(cardCode)}`)
  }
  const columns = columnClient(
    t,
    handleRowClick,
    handleCardNameChange,
    handleCardTypeChange,
    handlePhoneNumberChange,
    handleAddressChange,
    handleSeeBtnClick,
  )

  return (
    <Layout>
      <Header
        title={t('clients')}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={data.length === 10}
        columns={columns}
        fdata={data}
        loading={isLoading}
        {...(roleNames.includes('admin') ? { onBtnClick: handleAddClick } : {})}
      />
      <ViewClientsModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => setIsModalVisible(false)}
      />

      <CreteCustomerModal
        visible={isCreateModalVisible}
        data={null}
        onClose={() => {
          refetch()
          setIsCreateModalVisible(false)
        }}
      />
    </Layout>
  )
}

export default Clients
