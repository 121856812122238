import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  setCollapse,
  setSelectedPath,
  setOpenMiniMenu,
} from '../../slice/mainSlice'
import logo from '../../assets/images/BIS logo.svg'
import '../../assets/style/menu.css'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SalesIcon from '../../assets/icons/SalesSvg'
import PaymentIcon from '../../assets/icons/PaymentSvg'
import ClientIcon from '../../assets/icons/ClientSvg'
import SalesStaticsIcon from '../../assets/icons/SalesStatics'
import ReportsIcon from '../../assets/icons/ReportsSvg'
import ItemsIcon from '../../assets/icons/itemsSvg'
import { get } from 'lodash'
import SalesIcon2 from '../../assets/icons/SalesSvg2'

export const Menubar = () => {
  const { t } = useTranslation()
  const { collapse, selectedPath, openMiniMenu, getMe } = useSelector(
    (state) => state.main,
  )
  const role = get(getMe, 'roleLines', [])
  const dispatch = useDispatch()
  const [menuHeight, setMenuHeight] = useState('auto')
  const navigate = useNavigate()

  const pages = {
    Wrhmanager: [
      {
        key: 'purchasement',
        label: t('purchasement'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'purchase-order',
            label: t('purchase-order'),
          },
          {
            key: 'purchase-incoming',
            label: t('purchase-incoming'),
          },
        ],
      },

      {
        key: 'sales',
        label: t('sales'),
        icon: <SalesIcon2 />,

        children: [
          {
            key: 'sales-order',
            label: t('SalesOrder'),
          },
          {
            key: 'shipped-sales',
            label: t('Shipped'),
          },
        ],
      },
    ],
    'Sales Manager': [
      {
        key: 'saleStatics',
        label: t('saleStatics'),
        icon: <SalesStaticsIcon />,
        children: [
          {
            key: 'my-sales',
            label: t('MySales'),
          },
          {
            key: 'debtors',
            label: t('Accounts receivable'),
          },
        ],
      },
      {
        key: 'sales',
        label: t('sales'),
        icon: <SalesIcon2 />,
        children: [
          {
            key: 'sales-order',
            label: t('SalesOrder'),
          },
          {
            key: 'shipped-sales',
            label: t('Shipped'),
          },
          {
            key: 'finished-sales',
            label: t('finishedSales'),
          },
        ],
      },
      {
        key: 'clients',
        label: t('clients'),
        icon: <ClientIcon />,
      },
    ],
    Supplier: [
      {
        key: 'purchasement',
        label: t('purchasement'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'purchase-order',
            label: t('purchase-order'),
          },
          {
            key: 'purchase-incoming',
            label: t('purchase-incoming'),
          },
          {
            key: 'purchase-invoices',
            label: t('purchase-invoices'),
          },
        ],
      },

      {
        key: 'outgoing-payments',
        label: t('outgoingPayment'),
        icon: <PaymentIcon />,
      },

      {
        key: 'reports',
        label: t('reports'),
        icon: <ReportsIcon />,
        children: [
          {
            key: 'reports',
            label: t('reportsWhs'),
          },
          {
            key: 'send-money',
            label: t('sendMoney'),
          },
        ],
      },
      {
        key: 'items',
        label: t('items'),
        icon: <ItemsIcon />,
      },
      {
        key: 'suppliers',
        label: t('suppliers'),
        icon: <UserOutlined style={{ fontSize: '25px', color: 'white' }} />,
      },
    ],
    Cashier: [
      {
        key: 'sales',
        label: t('sales'),
        icon: <SalesIcon2 />,

        children: [
          {
            key: 'sales-order',
            label: t('SalesOrder'),
          },
          {
            key: 'sales',
            label: t('sales'),
          },
        ],
      },
      {
        key: 'payments',
        label: t('payments'),
        icon: <PaymentIcon />,
        children: [
          {
            key: 'outgoing-payments',
            label: t('outgoingPayment'),
          },
          {
            key: 'incoming-payments',
            label: t('incomingPayment'),
          },
        ],
      },
      {
        key: 'cash',
        label: t('kassa'),
        icon: <PaymentIcon />,
        children: [
          {
            key: 'balance',
            label: t('balance'),
          },
          {
            key: 'send-money',
            label: t('sendMoney'),
          },
        ],
      },
    ],
    admin: [
      {
        key: 'whs',
        label: t('whs'),
        icon: <ReportsIcon />,
        children: [
          {
            key: 'reports',
            label: t('warehouses'),
          },
          {
            key: 'itemsAdmin',
            label: t('items'),
          },
          {
            key: 'stock-transfers',
            label: t('stockTransfers'),
          },
        ],
      },
      {
        key: 'sales',
        label: t('salesDepartment'),
        icon: <SalesIcon2 />,
        children: [
          {
            key: 'sales-order',
            label: t('orders'),
          },
          {
            key: 'clients',
            label: t('clients'),
          },
          {
            key: 'branches',
            label: t('branches'),
          },
          {
            key: 'itemsServices',
            label: t('services'),
          },
          {
            key: 'package-services',
            label: t('packageServices'),
          },
        ],
      },
      {
        key: 'production',
        label: t('production'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'sales-order',
            label: t('orders'),
          },
        ],
      },

      {
        key: 'accounting',
        label: t('accounting'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'expected-payments',
            label: t('expectedPayments'),
          },
          {
            key: 'incoming-payments',
            label: t('incomingAdmin'),
          },
          {
            key: 'cashs',
            label: t('cashs'),
          },

          {
            key: 'outgoing-payments',
            label: t('expenses'),
          },
          {
            key: 'purchase-order',
            label: t('purchase-order'),
          },
          {
            key: 'purchase-incoming',
            label: t('purchase-incoming'),
          },
          {
            key: 'purchase-invoices',
            label: t('purchase-invoices'),
          },

          {
            key: 'debtors',
            label: t('Accounts receivable'),
          },
        ],
      },
      {
        key: 'HR',
        label: t('HR'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'employees',
            label: t('employees'),
          },
        ],
      },

      {
        key: 'pricing',
        label: t('pricing'),
        icon: <SalesIcon />,
        children: [
          {
            key: 'itemsTable',
            label: t('itemsTable'),
          },
          {
            key: 'servicesTable',
            label: t('servicesTable'),
          },
        ],
      },
      {
        key: 'debtors',
        label: t('Accounts receivable'),
        icon: <UserOutlined style={{ fontSize: '25px', color: 'white' }} />,
      },

      // {
      //   key: 'suppliers',
      //   label: t('suppliers'),
      //   icon: <UserOutlined style={{ fontSize: '25px', color: 'white' }} />,
      // },
    ],
  }

  useEffect(() => {
    const menu = document.getElementById('menu')
    if (menu) {
      setMenuHeight(`${menu.scrollHeight}px`)
    }
  }, [collapse])

  const toggleCollapsed = () => {
    dispatch(setCollapse(!collapse))
  }
  const onSelectMenuItem = (item) => {
    dispatch(setSelectedPath(item.key))
    navigate(`/${item.key}`)
  }

  const uniquePages = () => {
    const combinedPages = Array.isArray(role)
      ? role.flatMap(({ roleName }) => pages[roleName] || [])
      : []

    const pageMap = new Map()
    combinedPages.forEach((page) => {
      if (!pageMap.has(page.key)) {
        pageMap.set(page.key, page)
      }
    })
    console.log(Array.from(pageMap.values()))
    console.log(Array.from(pageMap.values()))

    return Array.from(pageMap.values())
  }

  const items = uniquePages()

  return (
    <div className="flex">
      <div
        className={`${collapse ? 'w-[80px]' : 'w-[295px]'} h-${menuHeight} bg-[#0A4D68]`}
      >
        <div className="flex justify-between px-5 mt-6 ">
          {collapse ? '' : <img src={logo} alt="" className=" w-[150px]" />}

          <button
            onClick={toggleCollapsed}
            className="bg-[#0A4D68] text-white text-2xl"
          >
            {collapse ? (
              <MenuUnfoldOutlined className="ml-3" />
            ) : (
              <MenuFoldOutlined />
            )}
          </button>
        </div>
        <div className="mt-20">
          <Menu
            defaultOpenKeys={openMiniMenu}
            selectedKeys={[selectedPath]}
            mode="inline"
            inlineCollapsed={collapse}
            items={items}
            className="bg-transparent m-auto"
            theme="light"
            onSelect={onSelectMenuItem}
            onOpenChange={(v) => {
              dispatch(setOpenMiniMenu(v))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Menubar
