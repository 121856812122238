import * as React from 'react'
const EditIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3865 3.83114L16.169 7.61364M1 19L4.67278 18.3008C5.45152 18.1526 6.16769 17.7736 6.72823 17.213L18.1837 5.75754C19.2721 4.66918 19.2721 2.90462 18.1837 1.81626C17.0954 0.72791 15.3308 0.727913 14.2425 1.81627L2.78696 13.2718C2.22642 13.8323 1.8474 14.5485 1.69916 15.3272L1 19Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default EditIcon
